import App from './App.vue'
import { createSSRApp as createVueApp } from 'vue'
import { registerBaseContext } from '../common/common_inject'
import { commentStore } from './store/ssr'
import { createHead } from '@vueuse/head'

export function createApp(context) {
  const store = commentStore(context)
  const app = createVueApp(App, {
    context
  })
  const head = createHead()
  registerBaseContext(app, context)
  app.use(head)
  app.use(store)
  return { app, store }
}
