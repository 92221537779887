import { createApp } from 'vue'
import { registerBaseContext } from '../../common/common_inject'

let instance = null

function create() {
  if (typeof window === 'undefined') return

  return new Promise((resolve) => {
    if (instance) {
      resolve(instance)
    } else {
      import('./dialog-group.vue').then(({ default: dialog }) => {
        // 创建实例
        const tempDiv = document.createElement('div')
        const app = createApp(dialog)
        registerBaseContext(app)
        // 手动挂载实例到 body
        document.body.appendChild(tempDiv)
        const vm = app.mount(tempDiv)
        instance = vm
        // 返回组件
        resolve(vm)
      })
    }
  })
}

function groupDialog() {
  return create()
}

export { groupDialog }
