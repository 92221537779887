
const labelTypeMap = new Map([
  ['gear', '01'],
  ['noGear', '02'],
  ['unifiedName', '03']
])

const views = {
  LIST_PC_STANDARD_VIEW: 'pcStandardView', // pc标准版
  LIST_PC_BROADWISE_VIEW: 'pcBroadwiseView', // pc横版
}

// 德国合规价pageId=1384131888
// 法国挪威合规价pageId=1347754607
// normal（接入合规价，但不包含lowest price，对应fr站点处理方案）
// special（接入合规价，并包含lowest price，对应pl站点处理方案）
// special_de（接入合规价，并包含lowest price、原价的样式，对应de站点处理方案
const apolloSuggestedSaleConfig = {
  NORMAL: 'normal',
  SPECIAL: 'special',
  SPECIALDE: 'special_de'
}

export {
  labelTypeMap,
  views,
  apolloSuggestedSaleConfig
}
