import { createRouter, createWebHistory } from 'vue-router'
import App from 'public/src/pages/goods_detail_reviews/App.vue'

const { langPath } = gbCommonInfo

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      // path: '/comment/goods-detail-reviews',
      path: '/:pathMatch(.*)*-review-:goodsId(\\d+).html',
      name: 'goods_detail_reviews',
      component: App,
    },
    {
      path: '/:pathMatch(.*)*',
      redirect() {
        location.href = `${langPath}/404.html?from=${encodeURIComponent(
          location.href
        )}`
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
 
  next()
})

export default router
