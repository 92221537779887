<template>
  <div
    class="date"
    tabindex="0"
    role="text"
    :aria-label="formattedTime"
  >
    {{ formattedTime }} 
  </div>
</template>

<script>
import dayjs from 'dayjs'

const { SiteUID, IS_RW } = gbCommonInfo
export default {
  name: 'CommentTime',
  inject: ['COMMENT_INSTANCE'],
  computed: {
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    isOutStoreReview() {
      const { comment } = this
      return comment.isOutStoreReview
    },
    formattedTime() {
      const { comment, isOutStoreReview } = this
      const { comment_time, add_time, add_time_str } = comment
      const { Reviewchange } = this.COMMENT_INSTANCE.screenAbtConfig || {}
      if(Reviewchange?.param?.Datedisplay === 'New' && add_time_str) {
        return add_time_str
      }
      if (isOutStoreReview) {
        return dayjs(comment_time).format('DD MMM,YYYY')
      }
      let date = new Date(add_time * 1000)
      let mLables = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
      if (SiteUID === 'jp' && !IS_RW) {
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
      }
      return (
        date.getDate() +
        ' ' +
        mLables[date.getMonth()] +
        ',' +
        date.getFullYear()
      )
    },
  },
}
</script>

<style lang="less" scoped>
.date {
  font-size: 13px;
  white-space: nowrap;
}
</style>
