<template>
  <div
    class="rate-star"
    tabindex="0"
    role="text"
    :aria-label="`${language.SHEIN_KEY_PC_15166}${comment.comment_rank}`"
  >
    <CommonRate
      :rank-num="comment.comment_rank"
      :size="'small'"
    />
  </div>
</template>

<script>
import CommonRate from 'public/src/pages/goods_detail_v2/components/CommonRate.vue'
export default {
  name: 'CommentRate',
  inject: ['COMMENT_INSTANCE'],
  components: { CommonRate },
  computed: {
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    language() {
      return this.COMMENT_INSTANCE.language
    }
  }
}
</script>
