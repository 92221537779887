export default {
  assignState(state, payload) {
    Object.assign(state, payload)
  },
  showLoginModal(state, payload = {}) {
    const { type = '', originRef = null, likeType, callback = null } = payload
  
    state.loginModal = {
      show: true,
      type,
      originRef,
      likeType,
      callback,
    }
    SHEIN_LOGIN.show({
      cb: (status) => {
        this.dispatch('loginModalSucCb', status)
      },
      from: likeType == 'add' ? 'wishlist' : 'other',
    })
  },
  hideLoginModal(state) {
    state.loginModal = {
      show: false,
      type: '',
      originRef: null,
      likeType: '',
      callback: null,
    }
  },
  setBeforeLoginLikeType(state, payload) {
    const { likeType } = payload
    state.beforeLoginLikeType = likeType
  },
  update_only_show_report(state, payload) {
    state.only_show_report = payload
  },
  update_default_lang(state, payload) {
    state.default_lang = payload
  },
  update_is_auto_translate(state, payload) {
    state.is_auto_translate = payload
  },
  update_reviews_tags(state, payload) {
    state.reviews_tags = payload
  },
  update_comment_rank_average(state, payload) {
    state.comment_rank_average = payload
  },
}
