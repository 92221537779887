import schttp from 'public/src/services/schttp'
import { userModule } from 'public/src/pages/common/user_module.js'


export default {
  showLoginModal({ commit }, data) {
    commit('showLoginModal', data)
  },
  hideLoginModal({ commit }) {
    commit('hideLoginModal')
  },
  setBeforeLoginLikeType({ commit }, data) {
    commit('setBeforeLoginLikeType', data)
  },
  loginModalSucCb({ dispatch, state, commit }, status) {
    const { callback } = state.loginModal || {}
    commit('assignState', { noResetUnfold: true })
    dispatch('getSheinClubUser')
    // 执行回调
    callback?.(status)
    // 收藏
    if (state.loginModal.type === 'qv-add-wish') {
      state.loginModal.originRef.addToWish()
    }

    if (state.loginModal.type === 'review_feedback') {
      if (!(typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window?.gbCommonInfo?.WEB_CLIENT === 'shein')) {
        state.loginModal.originRef.refreshReviewFeedback()
      }
    }

    if (state.loginModal.type === 'detail_coupon') {
      state.loginModal.originRef.isLogin = true
    }

    if (state.loginModal.type === 'reviews_tologin') {
      state.loginModal.originRef?.onAfterLoginToShowMoreComment()
    }


    state.isLoginChange = true

    dispatch('setBeforeLoginLikeType', {
      likeType: state.loginModal.likeType,
    })

    dispatch('hideLoginModal')

    // 购物车模块刷新
    cart_module.reload()

    // 顶部用户模块刷新
    userModule.update_top_login()


  },
  async getSheinClubUser({ state }) {
    const res = await schttp({
      url: '/api/comment/sheinClubUser/get',
      method: 'get'
    })

    state.isPaidUser = res?.isPaidUser
  },
  // 点赞
  async like_click_action(
    { rootState },
    { comment_id, current_member_zan, sku }
  ) {
    let url = ''
    let method = 'GET'
    let params = {}
    const { goods_sn } = rootState.productIntroData?.detail || {}
    const isRp = comment_id.toString().indexOf('rp') > -1
    const currThumbsUp = Number(current_member_zan) == 1 ? 0 : 1
    if (isRp) {
      url = 'user/trial/like_report'
      params = {
        report_id: comment_id.replace('rp', ''),
        action: currThumbsUp,
        sku: sku || goods_sn
      }
      method = 'POST'
    } else {
      url = '/product/like_goods_review'
      params = {
        comment_id,
        thumbs_up: currThumbsUp,
        sku: sku || goods_sn
      }
    }
    return await schttp({
      url,
      params,
      useBffApi: true,
      method
    })
  },
  // 批量翻译
  async fetch_batch_translate(
    { state, rootState },
    comment_info_data = []
  ) {
    const comment_infos = []
    const { default_lang } = state
    comment_info_data.length &&
      comment_info_data.forEach(i => {
        if (
          !i.reportId &&
          (!i.translateText || i.current_lang !== default_lang)
        ) {
          comment_infos.push({
            comment_id: i.comment_id,
            src_language: i.language_flag
          })
        }
      })
    if (comment_infos.length) {
      const { goods_spu } = rootState.detail || {}
      let data = {}
      const comment_ids = []
      const srcLanguages = []
      comment_infos.slice(0, 20).forEach(item => {
        const { comment_id, src_language } = item || {}
        comment_ids.push(comment_id)
        srcLanguages.push(src_language)
      })
      data = await schttp({
        url: '/product/comment/batch_translate',
        method: 'POST',
        useBffApi: true,
        params: {
          dest_language: default_lang,
          goods_spu: goods_spu,
          comment_ids: comment_ids.join(','),
          src_languages: srcLanguages.join(',')
        }
      })

      if (data.code == 0 && data.info?.comment_infos?.length) {
        let translateComments = data.info.comment_infos

        let commentInfoDataForAutoTranslate =
          Array.isArray(comment_info_data) &&
          comment_info_data.map(i => {
            const hitTransComment = translateComments.find(
              j => j.comment_id === i.comment_id
            )
            const translateText =
              hitTransComment?.dest_text ||
              (i.current_lang !== default_lang &&
                default_lang === i.language_flag &&
                i.content) ||
              ''
            const translateTags = hitTransComment?.dest_content_tag || []
            const translateSelectTags = hitTransComment?.dest_select_tag || []
            let obj = {
              ...i,
              showOrigin:
                typeof i.showOrigin === undefined
                  ? i.showOrigin
                  : !state.is_auto_translate
            }
            if (obj.translateText === undefined || translateText) {
              obj.translateText = translateText
              obj.translateTags = translateTags
              obj.translateSelectTags = translateSelectTags
              obj.current_lang = default_lang
            }
            return obj
          })
        return commentInfoDataForAutoTranslate
      }
      return comment_info_data
    }
    return comment_info_data
  }
}
