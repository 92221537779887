<template>
  <div class="gd-detail">
    <template
      v-if="memberSizeNew.length"
    >
      <template
        v-for="(member_size, index2) in memberSizeNew"
        :key="`size___${index2}`"
      >
        <div
          class="gd-detail-item"
        >
          <span :style="{'font-weight':showTotal && 'bold'}">{{ member_size.language_name }}:</span>
          {{ member_size.size_value }}
        </div>
      </template>

      <div
        v-if="!showTotal"
        class="common-reviews__list-item-more"
      >
        <span>
          {{ language.SHEIN_KEY_PC_15663 }}
          <Icon 
            name="sui_icon_more_down_14px" 
            size="14px"
            color="#999"
          />
        </span>
        <div class="common-hovertips common-hovertips_pos-reviewspop">
          <template
            v-for="(member_size, index3) in memberSizeNew"
            :key="`size-more__${index3}`"
          >
            <div v-if="index3 > 1">
              <span>{{ member_size.language_name }}:</span>
              {{ member_size.size_value }}
            </div>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <template
        v-for="(member_size, index2) in handleSize(comment.member_size)"
        :key="`size___${index2}`"
      >
        <div
          v-if="index2 < 2 || showTotal"
          class="gd-detail-item"
        >
          <span :style="{'font-weight':showTotal && 'bold'}">{{ member_size.title }}</span>
          {{ member_size.value }}
        </div>
      </template>
      <div
        v-if="handleSize(comment.member_size).length > 2 && !showTotal"
        class="common-reviews__list-item-more"
      >
        <span>
          {{ language.SHEIN_KEY_PC_15663 }}
          <Icon 
            name="sui_icon_more_down_14px" 
            size="14px"
            color="#999"
          />
        </span>
        <div class="common-hovertips common-hovertips_pos-reviewspop">
          <template
            v-for="(member_size, index3) in handleSize(comment.member_size)"
            :key="`hovertips__${index3}`"
          >
            <div v-if="index3 > 1">
              <span>{{ member_size.title }}</span>
              {{ member_size.value }}
            </div>
          </template>
        </div>
      </div>
    </template>
    <!-- Slot 为新布局做的一个插槽 -->
    <slot name="fit"></slot>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'

export default {
  name: 'CommentSize',
  components: {
    Icon
  },
  inject: ['COMMENT_INSTANCE'],
  props: {
    showTotal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    memberSizeNew() {
      return this.comment?.member_size_new || []
    },
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    language() {
      return this.COMMENT_INSTANCE.language
    },
  },
  methods: {

    // 按顺序返回数据
    handleSize(data) {
      let info = []
      if (data['member_weight']) {
        info.push({
          title: this.language.SHEIN_KEY_PC_14642,
          value: data['member_weight']
        })
      }
      if (data['member_height']) {
        info.push({
          title: this.language.SHEIN_KEY_PC_14643,
          value: data['member_height']
        })
      }
      if (data['member_bust']) {
        info.push({
          title: this.language.SHEIN_KEY_PC_14644,
          value: data['member_bust']
        })
      }
      if (data['member_waist']) {
        info.push({
          title: this.language.SHEIN_KEY_PC_14645,
          value: data['member_waist']
        })
      }
      if (data['member_hips']) {
        info.push({
          title: this.language.SHEIN_KEY_PC_14646,
          value: data['member_hips']
        })
      }
      if (data['member_bra_size'] && this.isShowBraSize) {
        info.push({
          title: this.language.SHEIN_KEY_PC_14626,
          value: data['member_bra_size']
        })
      }
      // report brasize 字段不一样
      if (data['member_brasize'] && this.isShowBraSize) {
        info.push({
          title: this.language.SHEIN_KEY_PC_14626,
          value: data['member_brasize']
        })
      }
      return info
    },
  }
}
</script>
