<template>
  <div class="comment-tag-box">
    <ul
      v-if="commentTags.length"
    >
      <li
        v-for="(tag, key) in commentTags"
        :key="key"
      >
        <span class="tag-name">{{ tag.name }}</span>:
        <span class="tag-content">{{ tag.content }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { expose } from 'public/src/pages/common/analysis/directive.js'

export default {
  name: 'ContentTag',
  directives: { expose },
  inject: ['COMMENT_INSTANCE'],
  computed: {
    comment() {
      return this.COMMENT_INSTANCE.comment
    },

    commentTags() {
      const { comment } = this
      if (!comment) {
        return []
      }
      const { content_tag } = comment
      if (!Array.isArray(content_tag)) {
        return []
      }
      return content_tag.filter(tag => tag && tag.name && tag.content)
    }
  },
}
</script>

<style lang="less" scoped>
.comment-tag-box {
  margin-top: -6px;
  margin-bottom: 10px;
  li {
    font-size: 16px;
    color: @sui_color_gray_dark2;
    margin-top: 4px;
    &:first-child {
      margin-top: 0;
    }

    .tag-content {
      color: @sui_color_gray_dark1;
    }
  }
}
</style>
