// 这里还要挂载替换
import { createApp } from './app'
import router from './router/index'
import infiniteScroll from '@sheinfe/vue-infinite-scroll'
const { app, store } = createApp()

if (window?.detailReviewsSsrData) {
  // app.use(infiniteScroll)
  store.replaceState({ ...store.state, ...window.detailReviewsSsrData })
}
app.use(infiniteScroll)
app.use(router)

router.isReady().then(() => {
  app.mount('#goods-detail-reviews') // vue3会多加一层div，  不会替换div

  if(typeof window !== 'undefined' && location?.search?.includes('detail_debug')) {
    window.__detail_store__ = store
  }
})

