<template>
  <div>
    <div
      class="rate-des"
      role="text"
      tabindex="0"
      v-html="htmlDecode({ text: comment.contentText, isHtml: true })"
    ></div>
  </div>
</template>

<script>
import { htmlDecode } from '@shein/common-function'
export default {
  name: 'ContentDes',
  inject: ['COMMENT_INSTANCE'],
  computed: {
    comment() {
      return this.COMMENT_INSTANCE.comment
    }
  },
  methods: {
    htmlDecode
  }
}
</script>