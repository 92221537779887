export const LOCAL_COMMENT_ABT_FLAG_MAP = new Map()
LOCAL_COMMENT_ABT_FLAG_MAP.set('type=B', 1)
LOCAL_COMMENT_ABT_FLAG_MAP.set('type=C', 2)
LOCAL_COMMENT_ABT_FLAG_MAP.set('type=D', 3)
LOCAL_COMMENT_ABT_FLAG_MAP.set('type=E', 4)

// 评论未登陆状态下最多显示数量
export const COMMENT_NO_LOGIN_SHOW_LIMIT = 150

// 评论详情查询数量上限
export const COMMENT_DETAIL_COUNT_UPPER_LIMIT = 1000

export const validStoreCode = store_code => {
  const code = parseInt(+store_code)
  return code && code !== 1
}

export const makeFreeList = list => {
  if (!Array.isArray(list) || !list.length) return []
  return list.map(item => {
    const comment_image = item.imgList.fullImg.concat(item.imgList.detailImg).map(image => {
      return {
        ...image,
        comment_id: 'rp' + image.reportId,
        member_image_middle: image.imgMiddle
      }
    })
    const member_size = {}
    if (item.memberMeasurement) {
      for (const attr in item.memberMeasurement) {
        if (
          [
            'member_weight',
            'member_height',
            'member_bust',
            'member_waist',
            'member_hips',
            'member_brasize'
          ].includes(attr)
        ) {
          member_size[attr] = item.memberMeasurement[attr]
        }
      }
    }

    return {
      ...item,
      page: '1',
      comment_id: 'rp' + item.reportId,
      language_flag: 'en',
      user_name: item.name,
      comment_rank: item.score,
      comment_time: item.addTime,
      like_num: item.likeCount,
      current_member_zan: item.memberIsLike,
      size: item.attrValue,
      color: list.color,
      comment_image,
      member_size,
      member_cat_size: {
        size_info: (item.memberMeasurement && item.memberMeasurement.cat_size) || [],
        member_overall_fit: item.memberOverallFit
      },
      add_time: Math.round(new Date(item.addTime.replace(/-/g, '/')).getTime() / 1000),
    }
  })
}
export const calOldGoodsAttr = ({ color, goods_std_attr, size }, language) => {
  let txtArr = []
  if (color) {
    txtArr.push(`${language.SHEIN_KEY_PC_14639}:${color}`)
  }
  if (goods_std_attr) {
    txtArr.push(`${language.SHEIN_KEY_PC_14656}:${goods_std_attr} / ${size}`)
  } else if (size) {
    txtArr.push(`${language.SHEIN_KEY_PC_14656}:${size}`)
  }
  return txtArr.join(' / ')
}
