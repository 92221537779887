<template>
  <div class="nikename">
    {{ userName }}
    <span v-if="comment.reportId">{{ language.SHEIN_KEY_PC_15774 }}</span>
  </div>
</template>

<script>
export default {
  name: 'CommentBase',
  inject: ['COMMENT_INSTANCE'],
  computed: {
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    isOutStoreReview() {
      const { comment } = this
      return comment.isOutStoreReview
    },
    userName() {
      const { comment, isOutStoreReview } = this
      if (isOutStoreReview) {
        return comment.username
      }
      return comment.user_name
    },
    language() {
      return this.COMMENT_INSTANCE.language
    }
  }
}
</script>
