<template>
  <s-popover
    v-model="showTips"
    :placemen="placemen"
    :content="language.SHEIN_KEY_PC_22899"
    :append-to-body="false"
    :prop-style="popStyle"
    class="comment-point__container"
    fix-disappear-position
    trigger="hover"
  >
    <template #reference>
      <div
        ref="PROGRAM_REF"
        class="point"
      >
        {{ language.SHEIN_KEY_PC_22898 }}
      </div>
    </template>
  </s-popover>
</template>

<script>
import { debounce } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
export default {
  name: 'CommentPointProgram',
  inject: ['COMMENT_INSTANCE'],
  props: {
    placemen: {
      type: String,
      default: 'bottom-end'
    },
  },
  data() {
    return {
      showTips: false,
    }
  },
  computed: {
    language() {
      return this.COMMENT_INSTANCE.language
    },
    popStyle() {
      return { width: '240px' }
    }
  },
  watch: {
    showTips(val) {
      if (val) {
        this.showTipsEvent()
      }
    }
  },
  methods: {
    showTipsEvent: debounce({
      func: function() {
        daEventCenter.triggerNotice({ daId: '2-20-4' })
      },
      wait: 300
    }),
  }
}
</script>
