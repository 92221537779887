<template>
  <div class="show-local__container">
    <div
      class="left"
      :class="{ 'no-local-reviews': isDisable }"
    >
      {{ language[isDisable ? 'SHEIN_KEY_PC_21186' : 'SHEIN_KEY_PC_21185'] }}
      <span
        v-if="!isDisable"
        class="rate-num"
      >{{ localRating || 0 }}</span>
    </div>
    <CommonRate
      v-if="!isDisable"
      :rank-num="localRating"
      size="small"
    />
    <div class="right">
      <s-switch
        v-model:value="status"
        :class="{ 
          'c-switch-ar': GB_cssRight,
          'nocursor': isDisable
        }"
        :disable="isDisable"
        @change="changeHandle"
        @click="clickLocalHandle()"
      />
    </div>
  </div>
</template>

<script>
import SSwitch from 'public/src/pages/goods_detail_v2/components/Switch.vue'
import CommonRate from 'public/src/pages/goods_detail_v2/components/CommonRate.vue'
import { daEventCenter } from 'public/src/services/eventCenter'
import { mapState, mapMutations } from 'vuex'
import { template } from '@shein/common-function'
const { GB_cssRight } = gbCommonInfo
export default {
  name: 'ShowLocalSwitch',
  components: { SSwitch, CommonRate },
  props: {
    commentLocal: {
      type: Object,
      default() {
        return {}
      },
    },
    comment: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      GB_cssRight,
      status: false,
    }
  },
  computed: {
    ...mapState(['only_local_status', 'language', 'detail']),
    globalRating() {
      return this.comment?.comment_rank_average || null
    },
    globalReviewCount() {
      return this.comment?.comment_num || null
    },
    localRating() {
      return this.commentLocal?.comment_rank_average || null
    },
    localReviewCount() {
      return this.commentLocal?.comment_num || null
    },
    isDisable() {
      return !this.localReviewCount
    },
  },
  mounted() {
    // 可能从商详带状态过来
    if(!this.isDisable && this.detail?.local_site_query_flag) {
      const status = !!this.detail?.local_site_query_flag
      this.assignState({ only_local_status: status })
      this.status = status
    }
    this.exposeLocalReviews()
  },
  methods: {
    ...mapMutations(['assignState']),
    localRateHTML(rate) {
      const { template, language } = this
      return template(rate, language.SHEIN_KEY_PC_21184)
    },
    changeHandle(val) {
      this.status = val
      this.assignState({ only_local_status: val })
      this.$emit('change', val)
    },
    exposeLocalReviews() {
      const {
        isDisable,
        globalRating,
        globalReviewCount,
        localRating,
        localReviewCount,
      } = this
      daEventCenter.triggerNotice({
        daId: '1-8-5-41',
        extraData: {
          global_rating: globalRating,
          global_review_count: globalReviewCount,
          local_rating: localRating,
          local_review_count: localReviewCount,
          status: isDisable ? 'unable' : this.status ? 'on' : 'off',
        },
      })
    },
    clickLocalHandle() {
      const { isDisable } = this
      daEventCenter.triggerNotice({
        daId: '1-8-5-42',
        extraData: {
          status: isDisable ? 'unable' : this.status ? 'on' : 'off',
        },
      })
    },
    template: template,
  },
  emits: ['change'],
}
</script>

<style lang="less" scoped>
.show-local__container {
  display: inline-flex;
  width: 100%;
  height: 45px;
  padding: 12px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  // margin: 20px 0 30px 0;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.08);
  .left {
    font-size: 16px;
    font-weight: 400;
    color: #222;
    margin-right: 4px;
    &.no-local-reviews {
      margin-right: 10px;
    }

    .rate-num {
      font-weight: 700;
    }
  }
  .right {
    .c-switch {
      border-radius: 48px;
      width: 36px;
      height: 20px;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      background: #e5e5e5;
      user-select: none;
      box-sizing: border-box;
      cursor: pointer;
      &:after {
        position: absolute;
        width: 18px;
        height: 18px;
        background: #fff;
        border-radius: 50%;
        top: 1px;
        left: 1px;
        content: ' ';
        transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      }
      &.c-switch-checked {
        /* stylelint-disable-line */
        background: #666;
        & when(@IS_RW) {
          background: #ff696e;
        }
        &:after {
          /* stylelint-disable-line */
          left: 100% /*rtl:ignore*/;
          margin-left: -19px /*rtl:ignore*/;
        }
      }
    }
    .c-switch-ar {
      .c-switch;
      &:after {
        right: 1px; /*rtl:ignore*/
      }
      &.c-switch-checked {
        /* stylelint-disable-line */
        &:after {
          /* stylelint-disable-line */
          right: 100% /*rtl:ignore*/;
          margin-right: -19px /*rtl:ignore*/;
        }
      }
    }
    .nocursor {
      cursor: not-allowed;
    }
  }

  .common-rate {
    margin-right: 24px;
  }
}
</style>
