<template>
  <!-- sticky-under-gallery 时显示 -->
  <div
    :class="{
      'common-reviews-new__list-item': true,
      'with-tip': showFirstStoreCommentTip,
    }"
  >
    <!-- 首条站外评论提示 -->
    <div
      v-if="showFirstStoreCommentTip"
      class="common-reviews__store-comment-tip"
    >
      {{ language.SHEIN_KEY_PC_25744 }}
    </div>
    <div class="common-reviews-new__top-baseinfo">
      <CommentBase />
      <CommentTime />
      <LocalTag
        v-if="markSingleLocalComment && !isOutStoreReview"
        :language="language"
      />
    </div>
    
    <div class="common-reviews-new__top-rating">
      <!-- Rate -->
      <CommentRate />
      <!-- 多维评分标签 -->
      <MultiRate v-if="!isOutStoreReview" />
      <div>
        <!-- MemberSize -->
        <CommentSize 
          v-if="showMemberSize && !isOutStoreReview" 
          :show-total="true" 
        >
          <!-- Fit -->
          <template #fit>
            <CommentFit />
          </template>  
        </CommentSize>
      </div>
    </div>
    <div
      class="common-reviews-new__middle-box"
      :style="{ 'display': position === 'popup' ? 'block' : '' }"
    >
      <div class="common-reviews-new__middle-text">
        <!-- Content Des -->
        <CommentDes />
        <!-- 评论引导标签 -->
        <CommentTag v-if="!isOutStoreReview" />
        <!-- Slot -->
        <slot name="ContentBottom"></slot>
      </div>
      <div 
        class="common-reviews-new__middle-img"
      >
        <!-- Images -->
        <CommentImages v-if="!isOutStoreReview" />
      </div>
    </div>
    <div class="common-reviews-new__bottom-helpful">
      <div class="comment-point__box">
        <!-- Point Program -->
        <template v-if="showPointProgram && !isOutStoreReview">
          <CommentPointProgram placemen="right" />
        </template>

        <!-- 相似商品 -->
        <template v-if="showSameFlag && comment.same_flag">
          <div
            v-expose="{ id: '2-20-5' }"
            class="comment-point__container split-left"
          >
            <div class="point">
              {{ language.SHEIN_KEY_PC_25739 }}
            </div>
          </div>
        </template>
      </div>
      <div class="comment-like__box">
        <CommentLike v-if="showLike && !isOutStoreReview" />
        <!-- Report -->
        <CommentReport
          v-bind="reportAttrs"
          position="left"
          @report-click="reportClickHandle"
          @report-submit="reportSubmitHandle"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import CommentContent from './CommentContent'
import CommentImages from './CommentImages'
import CommentBase from './CommentBase'
import CommentSize from './CommentSize'
import CommentLike from './CommentLike'
import CommentTime from './CommentTime'
import CommentReport from './CommentReport'
import CommentPointProgram from './CommentPointProgram'
import LocalTag from './LocalTag'
import Props from './utils/props'
import CommentFit from './CommentFit'
import CommentRate from './CommentRate'
import CommentDes from './CommentDes'
import CommentTag from './CommentTag'
import MultiRate from './MultiRate.vue'

export default {
  name: 'CommonComment',
  components: {
    CommentBase,
    CommentSize,
    // CommentContent,
    CommentImages,
    CommentLike,
    CommentTime,
    CommentReport,
    CommentPointProgram,
    LocalTag,
    CommentRate,
    CommentFit,
    CommentDes,
    CommentTag,
    MultiRate,
  },
  props: Props,
  computed: {
    activityFrom() {
      return this.analysis?.activity_from || ''
    },
    reportAttrs() {
      const { analysis, comment, skc } = this
      const commentId = (comment?.comment_id || '') + ''
      const isFree = !!~commentId.indexOf('rp')
      const id = commentId.replace('rp', '')
      return {
        commentId: id,
        isOutStoreReview: !!comment.isOutStoreReview,
        isFree,
        analysis,
        skc,
        reportMemberId: comment?.member_id || '',
      }
    },
    isOutStoreReview() {
      const { comment } = this
      return comment.isOutStoreReview
    },
    showFirstStoreCommentTip() {
      const { comment, isOutStoreReview } = this
      if (!isOutStoreReview) {
        return false
      }
      const { isFirstOne, rankIndex } = comment
      return isFirstOne && rankIndex !== 1
    },
  },
  provide() {
    return {
      COMMENT_INSTANCE: this,
    }
  },
  methods: {
    // 事件派发
    EVENT_CENTER(key, data) {
      this.$emit(key, data)
    },
    reportClickHandle(params) {
      this.EVENT_CENTER('reportClick', params)
    },
    reportSubmitHandle(params) {
      this.EVENT_CENTER('reportSubmit', params)
    },
  },
}
</script>

<style lang="less">
.common-reviews-new {
  display: none;
  &__list-item {
    display: none;
    position: relative;
    padding: 16px 0;
    border-bottom: 1px dashed #E5E5E5;
    &.with-tip {
      position: relative;
      padding-top: 72px;
    }
    
  }
  &__top-baseinfo {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .nikename {
      color: #222;
      font-weight: bold;
      white-space: normal;
      line-height: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 4px;
      span {
        font-size: 12px;
        color: #67b17c;
        border: 1px solid rgba(103, 177, 124, 1);
        padding: 1px 4px;
        font-weight: normal;
        margin: 0 0 0 6px;
      }
    }
  }
  &__top-rating {
    .rate-star{
       margin-bottom: 8px;
    }
    .multi-rate-box{
      margin-bottom: 5px;
    }
    .gd-detail {
      display: flex;
      flex-wrap: wrap;
    }
    .rate-fit__item, .gd-detail-item{
      margin-right: 16px;
      margin-bottom: 5px;
      font-size: 13px;
      line-height: 13px;
      color: #999999;
    }
  }
  &__middle-box{
    display: flex;
    margin: 16px 0;
  }
  &__middle-text {
    flex: 1;
    .comment-tag-box {
      margin-top: 0;
    }
    .rate-des{
      margin-bottom: 10px;
      font-size: 16px;
      color: #222;
      line-height: 21px;
      word-break: break-word;
    }
  }
  &__middle-img {
    .common-reviews__list-item-pic{
      .pic-item {
        width: 98px;
        height: 98px;
        line-height: 98px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
    
  }
  &__bottom-helpful {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .comment-like__box{
      display: flex;
    }
    .comment-like__container {
      display: flex;
      align-items: center;
      .like-num {
        margin: 0 20px 0 4px;
      }
      .thumb-up {
        border: none;
        background: transparent;
        i {
          cursor: pointer;
        }
      }
    }
    .comment-point__box{
      display: flex;
      
      .comment-point__container{
        margin-right: 6px;
        .point{
          cursor: default;
          padding: 2px 6px;
          background-color: #f6f6f6;
          font-size: 10px;
          line-height: 15px;
          color: #959595;
          white-space: nowrap;
        }
      }
    }
    .comment-report__container {
      color: #222;
    }
  }
}

.common-reviews {
  &.sticky-under-gallery {
    .common-reviews__list-item {
      display: none;
    }
    .common-reviews-new__list-item {
      display: block;
    }
  }
  &__list-item {
    display: inline-flex;
    position: relative;
    width: 100%;
    padding: 25px 0;
    border-bottom: 1px dashed #e5e5e5;

    &.with-tip {
      position: relative;
      padding-top: 72px;
    }
  }

  &__store-comment-tip {
    color: @sui_color_gray_dark2;
    font-size: 14px;
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    height: 32px;
    box-sizing: border-box;
    padding: 0 16px;
    text-align: center;
    line-height: 32px;
    background-color: #f6f6f6;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__list-item-left {
    .fl();
    width: 200px;
    white-space: nowrap;

    .nikename {
      margin-bottom: 15px;
      color: #222;
      font-weight: bold;
      white-space: normal;
      line-height: 2;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        font-size: 12px;
        color: #67b17c;
        border: 1px solid rgba(103, 177, 124, 1);
        padding: 1px 4px;
        font-weight: normal;
        margin: 0 0 0 6px;
      }
    }
    .gd-detail {
      position: relative;
      line-height: 18px;
    }
    .gd-detail-item {
      span {
        color: #222;
      }
    }

    .bottom-container {
      position: absolute;
      .left(0);
      bottom: 28px;
      display: flex;
      align-items: center;
    }
  }
  &__list-item-more {
    position: relative;
    color: #222;
    display: inline-block;
    cursor: pointer;
    .common-hovertips_pos-reviewspop {
      display: none;
    }
    &:hover {
      .common-hovertips_pos-reviewspop {
        display: block;
      }
    }
  }
  &__list-item-mid {
    .fl();
    width: calc(~'100% - 288px');
    .padding-r(150px);
    display: flex;
  }
  &__list-item-detail {
    .padding-r(40px);
    width: 50%;
    .rate-star {
      margin-bottom: 7px;
    }
    .rate-des {
      margin-bottom: 10px;
      font-size: 16px;
      color: #222;
      line-height: 21px;
      word-break: break-word;
    }
    .rate-fit {
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 13px;
      line-height: 13px;
      color: #999999;
      padding-top: 10px;
      .rate-fit__item {
        .margin-r(14px);
        margin-bottom: 5px;
      }
      strong {
        text-transform: capitalize;
      }
      .goods-id {
        margin-bottom: 5px;
        cursor: pointer;
      }
    }
  }
  &__list-item-pic {
    width: 50%;
    display: flex;
    .pic-item {
      position: relative;
      .margin-r(5px);
      flex-shrink: 0;
      width: 108px;
      height: 108px;
      line-height: 108px;
      overflow: hidden;
      cursor: pointer;
      background-image: url('/she_dist/images/bg-grey-ba96515e9b.png');
      background-size: cover;
      background-repeat: no-repeat;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
    .pic-item-more {
      position: absolute;
      bottom: 0;
      .right(0);
      width: 30px;
      height: 22px;
      line-height: 22px;
      color: #fff;
      background: fade(#000, 60%);
      text-align: center;
    }
  }
  &__list-item-right {
    position: relative;
    width: 80px;
    .thumb-up {
      color: #222;
      border: none;
      background: transparent;
      white-space: nowrap;
      &.eval-selected {
        color: #222;
        i {
          color: #222;
        }
        .like-completed-icon {
          /* rw:begin */
          color: @sui_color_main;
        }
      }
      i {
        .margin-l(5px);
        width: 18px;
        height: 18px;
        vertical-align: sub;
        cursor: pointer;
        color: #222;
      }
    }
    .comment-action {
      position: absolute;
      height: 100%;
      top: 0;
      .right(0);
      display: inline-flex;
      flex-wrap: nowrap;
      .comment-report__container {
        margin-left: 28px;
      }
      .comment-like__container {
        display: inline-flex;
        align-items: center;
        height: 28px;
        .like-num {
          margin-left: 7px;
          font-weight: 400;
          font-size: 12px;
          color: #767676;
          white-space: nowrap;
        }
      }
      .comment-point__box {
        position: absolute;
        display: inline-flex;
        bottom: 0;
        .right(0);
        align-items: center;
      }
      .comment-point__container {
        .point {
          cursor: default;
          padding: 2px 6px;
          font-size: 10px;
          line-height: 15px;
          color: #bbb;
          white-space: nowrap;
          max-width: 160px;
          overflow: hidden;
          text-overflow: ellipsis;
          background-color: @sui_color_gray_weak2;
          /* rw:begin */
          background-color: inherit;
          border: 1px solid @sui_color_gray_weak2;
        }
        .sui-popover__main {
          left: inherit !important; /* stylelint-disable-line declaration-no-important */
          .right(
            0
          ) !important; /* stylelint-disable-line declaration-no-important */
        }
        &.split-left {
          margin-left: 6px;
        }
      }
    }
  }
  @media (max-width: 1037px) {
    .common-reviews__list-item-mid {
      flex-wrap: wrap;
    }
    .common-reviews__list-item-detail,
    .common-reviews__list-item-pic {
      width: 100%;
      .padding-r(0);
    }
    .common-reviews__list-item-pic {
      margin-top: 15px;
    }
  }
}
</style>
