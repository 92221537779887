<template>
  <div class="comment-like__container">
    <button
      class="thumb-up"
      tabindex="0"
      :aria-label="
        comment.current_member_zan == 1
          ? language.SHEIN_KEY_PC_17956
          : language.SHEIN_KEY_PC_17999
      "
      :class="{ 'eval-selected': comment.current_member_zan == 1 }"
      @click="likeClick()"
    >
      <Icon
        v-if="comment.current_member_zan == 1"
        class="like-completed-icon"
        name="sui_icon_like_completed_24px"
        size="24px"
        :custom-style="{ color: 'none'}"
      />
      <Icon
        v-else
        class="like-start-icon"
        name="sui_icon_like_24px"
        size="24px"
      />
    </button>
    <template v-if="comment.like_num != 0">
      <span class="like-num">{{ language.SHEIN_KEY_PC_17999 }} ({{ comment.like_num }})</span>
    </template>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'

export default {
  name: 'CommentLike',
  components: { Icon },
  inject: ['COMMENT_INSTANCE'],
  computed: {
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    language() {
      return this.COMMENT_INSTANCE.language
    }
  },
  methods: {
    likeClick() {
      const { COMMENT_INSTANCE, comment } = this
      COMMENT_INSTANCE.EVENT_CENTER('likeClick', comment)
    }
  }
}
</script>

<style lang="less">
.comment-like__container{
  .like-start-icon {
    cursor: pointer;
  }
}
</style>
