<template>
  <div 
    v-if="priceAndDiscountInfo.finalPrice.amountWithSymbol" 
    class="price-simple-suggested"
  >
    <div class="sale-price-container">
      <ProductCardPriceSimple
        :goods-info="goodsInfo"
        :is-paid="isPaid"
        :config="simpleComponentConfig"
        direction="row"
      />

      <!-- 价格右边 -->
      <SuggestedSalePriceContainer 
        v-if="priceAndDiscountInfo.priceRight.show"
        :goods-info="goodsInfo"
        :price-data="priceAndDiscountInfo.priceRight"
      />
    </div>
    <!-- 价格下方 -->
    <SuggestedSalePriceVue
      v-if="priceAndDiscountInfo.priceBottom.show"
      :value="priceAndDiscountInfo.priceBottom.priceData"
      :label-text="priceAndDiscountInfo.priceBottom.labelText"
      :flip-text="priceAndDiscountInfo.priceBottom.flipText"
      :price-delete-style="priceAndDiscountInfo.priceBottom.priceDeleteStyle"
    />
  </div>
</template>

<script name="ProductCardPriceSimpleSuggested" setup>
// 外部使用 不依赖config 简易版价格组件
// 只有价格以及价格二期需求下方文案 如Estimated

import { toRefs, computed } from 'vue'

import { ProductCardPriceAndDiscountInfo } from '../../utils/ProductCardPriceAndDiscountInfo/index.js'

import SuggestedSalePriceContainer from './SuggestedSalePriceContainer.vue'
import ProductCardPriceSimple from './ProductCardPriceSimple.vue'
import SuggestedSalePriceVue from './SuggestedSalePrice.vue'

const props = defineProps({
  /**
   * @param {Object} 商卡预处理后的数据
   */
  goodsInfo: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
  /**
   * @param {Object} 商卡多语言
   */
  language: {
    type: Object,
    default() {
      return {}
    },
  },
  /**
   * @param {Boolean} 是否付费会员
   */
  isPaid: {
    type: Boolean,
    default: false
  },
  /**
   * @param {String: 'column' | 'row' } 价格与文字标签的排列方式  column竖向  row横向
   */
  direction: {
    type: String,
    default: 'column'
  },

  /**
   * @typedef {Object} SceneConfig
   * @property {boolean} hidePriceBottomLabel - 是否隐藏价格旁边的标签
   * @property {boolean} noCamelCasePrice - 是否不启用大小字价格
   * @property {boolean} showS3VipPriceOnSale - 是否展示S3会员价格
   * @property {string|undefined} priceColor - 自定义价格颜色
   *
   * @param {SceneConfig} 具体场景自定义配置项
   */
  config: {
    type: Object,
    default() {
      return {
        hidePriceBottomLabel: false,
        noCamelCasePrice: false,
        showS3VipPriceOnSale: false,
      }
    }
  }
})

const { goodsInfo, language, isPaid, config } = toRefs(props)

const priceAndDiscountInfo = computed(() => {
  const suggestedSalePriceConfigInfo = props.suggestedSalePriceConfigInfo

  return new ProductCardPriceAndDiscountInfo({
    goodsInfo: goodsInfo.value, 
    language: language.value, 
    config: props.config,
    isPaid: !!isPaid.value,
    suggestedSalePriceConfigInfo: suggestedSalePriceConfigInfo
  })
})

const simpleComponentConfig = computed(() => {
  // 任意一个合规价展示，都不要展示价格说明文字
  if (priceAndDiscountInfo.value.priceRight.show || priceAndDiscountInfo.value.priceBottom.show) {
    return Object.assign({}, config.value, {
      hidePriceBottomLabel: true
    })
  } else {
    return config.value
  }
})

</script>

<style lang="less" scoped>
.sale-price-container {
  max-width: 100%;
  display: flex;
  align-items: baseline;
}
</style>
