<template>
  <div 
    v-if="priceAndDiscountInfo.finalPrice" 
    class="product-card__price-simple"
    :class="containerCls"
  >
    <div 
      class="price-content"
      :style="priceContentStyle"
    >
      <CamelCasePrice
        :price="priceAndDiscountInfo.finalPrice"
        :max-size="camelCasePriceMaxSize"
        :min-size="camelCasePriceMinSize"
        :is-camel-case-price="!noCamelCasePrice"
      />
    </div>
    <template v-if="!hidePriceBottomLabel">
      <slot 
        :display-price="priceAndDiscountInfo.finalPrice"
        :price-type="priceAndDiscountInfo.finalPriceType"
      >
        <!-- 标签插槽 -->
        <div 
          v-if="priceAndDiscountInfo.priceLabelText && !hideTargetBySuggestedSalePrice" 
          class="product-card__text-label"
        >
          {{ priceAndDiscountInfo.priceLabelText }}
        </div>
      </slot>
    </template>
  </div>
</template>

<script name="ProductCardPriceSimple" setup>
// 外部使用 不依赖config 简易版价格组件
// 只有价格以及价格二期需求下方文案 如Estimated

import { ref, toRefs, computed } from 'vue'
import { ProductCardPriceAndDiscountInfo } from '../../utils/ProductCardPriceAndDiscountInfo/index.js'

import CamelCasePrice from 'public/src/pages/components/CamelCasePrice.vue'

const props = defineProps({
  /**
   * @param {Object} 商卡预处理后的数据
   */
  goodsInfo: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
  /**
   * @param {Object} 商卡多语言
   */
  language: {
    type: Object,
    default() {
      return {}
    },
  },
  /**
   * @param {Boolean} 是否付费会员
   */
  isPaid: {
    type: Boolean,
    default: false
  },
  /**
   * @param {String: 'column' | 'row' } 价格与文字标签的排列方式  column竖向  row横向
   */
  direction: {
    type: String,
    default: 'column'
  },
  
  /** 外部自定义合规价配置，给没经过中间层预处理的场景用 */
  suggestedSalePriceConfigInfo: {
    type: Object,
    default: () => ({}),
  },

  /**
   * 一些展示隐藏配置项
   * @param {
   *    是否隐藏价格标签
   *    hidePriceBottomLabel: Boolean
   *    是否不启用大小字加个
   *    noCamelCasePrice: Boolean
   *    当价格使用大小字，最大的价格字号
   *    camelCasePriceMaxSize
   *    当价格使用大小字，最小的价格字号
   *    camelCasePriceMinSize
   *    priceColor - 自定义价格颜色
   * }
   */
  config: {
    type: Object,
    default() {
      return {}
    }
  }
})

const { goodsInfo, language, isPaid } = toRefs(props)

const priceAndDiscountInfo = computed(() => {
  const suggestedSalePriceConfigInfo = props.suggestedSalePriceConfigInfo

  return new ProductCardPriceAndDiscountInfo({
    goodsInfo: goodsInfo.value, 
    language: language.value, 
    config: props.config,
    isPaid: !!isPaid.value,
    suggestedSalePriceConfigInfo: suggestedSalePriceConfigInfo
  })
})


// props.config
const hidePriceBottomLabel = props?.config?.hidePriceBottomLabel ?? false
const noCamelCasePrice = props?.config?.noCamelCasePrice ?? false
const camelCasePriceMaxSize = props?.config?.camelCasePriceMaxSize ?? 18
const camelCasePriceMinSize = props?.config?.camelCasePriceMinSize ?? 12

const priceContentStyle = ref({
  color: props.config?.priceColor,
})

const hideTargetBySuggestedSalePrice = computed(() => {
  const suggestedSalePriceData = priceAndDiscountInfo.value?.suggestedSalePriceData ?? {}

  return suggestedSalePriceData.config?.hideTargetBySuggestedSalePrice ?? false
})

const containerCls = computed(() => {
  return {
    [priceAndDiscountInfo.value.color]: true,
    [props.direction]: true,
  }
})

</script>

<style lang="less" scoped>
.product-card__price-simple {
  display: flex;
  max-width: 100%;

  &.column {
    flex-direction: column;
  }

  &.row {
    margin-left: 0;
    margin-right: 0;

    flex-direction: row;
    align-items: baseline;
  }


  &.brown {
    color: var(--simple-brown-color, @sui_color_club_rosegold_dark1);
  }

  &.origin {
    color: var(--simple-origin-color, @sui_color_discount);
  }

  &.darkGold {
    color: var(--simple-darkGold-color, #806208);
  }

  .price-content {
    padding-right: 6px;
    font-weight: bold;
  }

  .product-card__text-label {
    font-size: 12px;
  }
}
</style>
