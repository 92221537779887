<template>
  <div 
    class="reviews-product"
  >
    <div class="reviews-product__wrap">
      <div class="reviews-product__left">
        <div class="reviews-product__img">
          <img :src="goodsInfo.goods_img" />
        </div>
        <div class="reviews-product__intro">
          <div class="reviews-product__title"> 
            {{ goodsInfo.goods_name }} 
          </div>
          <!-- 价格 -->
          <ProductCardPriceSimpleAutoComplianceMode
            class="reviews-product__price"
            :goods-info="goodsInfo"
            :is-paid="isPaidUser"
            :config="{
              showS3VipPriceOnSale: true,
              noCamelCasePrice: true
            }"
          />
        </div>
      </div>

      <div class="reviews-product__right">
        <!-- 加车按钮 -->
        <div
          class="reviews-product__container-addBag"
          :class="{ disabled: isCurrentSoldOut || isAllSoldOut }"
          @click="handleAddToBagHandle"
        >
          <template v-if="addToBagLoading">
            <div class="la-ball-pulse la-ball-pulse-white">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </template>
          <template v-else>
            <!-- {{ language.SHEIN_KEY_PC_14634 || 'ADD TO CART'}} -->
            {{
              isCurrentSoldOut || isAllSoldOut
                ? language.SHEIN_KEY_PC_14617
                : showAddedText
                  ? language.SHEIN_KEY_PC_14604
                  : language.SHEIN_KEY_PC_14634
            }}
          </template>
        </div>

        <!-- 收藏按钮 -->
        <!-- <div`
          class="reviews-product__like reviews-product__horizontal"
          :aria-label="language.SHEIN_KEY_PC_17382"
          @click.stop="handleClickAddWish"
        >
          <template v-if="hasSave">
            <sui_icon_save_completed_32px
              class="reviews-product__like-icon main-color anim"
              size="32"
              color="#222222" 
            />
          </template>
          <template v-else>
            <sui_icon_save_32px
              class="reviews-product__like-icon main-color"
              size="32"
              color="#222222"
            />
          </template>
        </div> -->
        <s-popover
          v-model="isShowAddGroup"
          class="reviews-product__like"
          placemen="top"
          trigger="unset"
          :hide-close-icon="true"
          :append-to-body="false"
        >
          <template #reference>
            <div
              class="product-intro__add-like"
              :class="hasSave ? 'like-active': ''"
              tabindex="0"
              :aria-label="language.SHEIN_KEY_PC_17382"
              role="button"
              @click="handleClickAddWish"
            >
              <template v-if="!hasSave">
                <sui_icon_save_32px
                  size="32"
                  class="hovertips"
                />
              </template>
              <template v-else>
                <sui_icon_save_completed_32px 
                  class="hovertips mian-color"
                  size="32"
                />
              </template>
            </div>
          </template>
          <!-- 收藏成功加组提示 -->
          <ClientOnly>
            <div
              class="product-intro__add-group"
              @touchstart.stop
              @click.stop="addGroup"
            >
              <Icon
                class="icon"
                name="sui_icon_addtoboard_28px_1"
                size="28px"
              />
              <span>{{ language.SHEIN_KEY_PC_17581 }}</span>
            </div>
          </ClientOnly>
        </s-popover>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, toRefs } from 'vue'
import { useMapActions, useMapState, useMapGetters } from 'public/src/pages/goods_detail_reviews/compositions/vuex.composition.js'
import ProductCardPriceSimpleAutoComplianceMode from 'public/src/pages/goods_detail_v2/components/ProductCardPriceSimpleAutoComplianceMode.vue'
import { Icon, sui_icon_save_completed_32px, sui_icon_save_32px } from '@shein-aidc/icon-vue3'
import schttp from 'public/src/services/schttp'
import { SMessage } from '@shein-aidc/sui-message'
import { template } from '@shein/common-function'
import { groupDialog } from 'public/src/pages/components/wishlist'
import { abtservice } from 'public/src/services/abt'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getSaGoodsList } from 'public/src/services/eventCenter/analysis/config/components/product-item'
import { isLogin } from 'public/src/pages/common/utils/index.js'

const props = defineProps({
  language: {
    type: Object,
    default: () => ({})
  }
})
const { language } = toRefs(props)

// goodsDetail属于未融合的node接口，后续可能会改动，尽量不要使用
const {
  goodsDetail,
  detail,
  isPaidUser,
} =  useMapState([
  'goodsDetail',
  'detail',
  'isPaidUser'
]) 

const {
  sku_list,
} =  useMapGetters([
  'sku_list',
])

const {
  showLoginModal,
} = useMapActions([
  'showLoginModal',
])

const goodsId = computed(() => detail.value.goods_id)

const goodsInfo = computed(() => goodsDetail.value?.[0] || {})

const skuInfo = computed(() => sku_list.value.find((item) => item.sku_code === detail.value.sku_code)) || {}

const isAllSoldOut = computed(() => {
  return Number(goodsInfo.value?.is_on_sale) === 0 || Number(goodsInfo.value?.stock) === 0
})
const isCurrentSoldOut = computed(()=> {
  return Number(skuInfo.value?.stock) === 0 || isAllSoldOut.value
})

// 埋点good_slist
const goods_list = computed(() => {
  let index = ''
  let pageNum = ''
  let spu = detail.value.goods_spu
  let sku = detail.value.sku
  let goodsId = detail.value.goods_id
  let price = ''
  let originPrice = ''
  let operId = ''
  let recMark = ''
  let extraMark = ''
  let ortherExtMark = ''
  let reducePrice = ''
  let soldOutSign = ''
  let soldoutMart = ''
  let exclusivePrice = ''
  let lable = ''
  let seriesOrBrand = ''
  let cccTspBadges = ''
  let sellingPointLabel = ''
  let videoIcon = ''
  let mallCode = ''

  return getSaGoodsList({ goodsId, sku, spu, index, pageNum, operId, recMark, extraMark, ortherExtMark, price, originPrice, reducePrice, soldOutSign, soldoutMart, exclusivePrice, lable, seriesOrBrand, cccTspBadges, sellingPointLabel, videoIcon, mallCode })
})

// 加车
const addToBagLoading = ref(false)
const showAddedText = ref(false)
const showAddedTextTimer = ref(null)

const handleAddToBagHandle = async() => {
  if (isCurrentSoldOut.value || isAllSoldOut.value) return // 售罄
  if (addToBagLoading.value) return
  addToBagLoading.value = true
  if(!detail.value.sku_code) {
    openQuickViewPopup()
    return
  }

  handleAddToBag()
  
}

// 加车逻辑
const handleAddToBag = async () => {
  if (isCurrentSoldOut.value || isAllSoldOut.value) return // 售罄

  const params = {
    goods_id: goodsId.value,
    quantity: 1,
    attrs: [],
    mall_code: detail.value.mall_code,
    sku_code: detail.value.sku_code,
  }

  skuInfo.value.sku_sale_attr?.forEach((item) => {
    params.attrs.push({
      attr_id: item.attr_id,
      attr_value_id: item.attr_value_id,
    })
  })

  addToBagLoading.value = true

  schttp({
    method: 'POST',
    data: params,
    url: '/api/cart/add_mall/create',
  })
    .then((res) => {
      if (res && Number(res.code) === 0 && res.info) {
        // 加车成功提示
        showAddedText.value = true
        clearTimeout(showAddedTextTimer.value)
        showAddedTextTimer.value = setTimeout(() => {
          showAddedText.value = false
        }, 1500)

        // 刷新购物车数据
        cart_module.recart(res)
      } else {
        SMessage({
          message: res.msg,
          type: 'warning',
          offset: 164,
          duration: 3000,
        })
      }
      // 加车埋点
      handleAddCartEvent({ result: 1 })

      addToBagLoading.value = false
    }).catch((err) => {
      handleAddCartEvent({ result: 0, result_reason: err?.msg || '' })

      if (err?.status == 403) {
        //更新csrf_token
        schttp.updateXCsrfToken()
      }
      addToBagLoading.value = false
    })
}
// 打开加车弹窗
const openQuickViewPopup = async() => {
  const QuickViewPopover = await quickViewPopoverInstancePromise.value
  QuickViewPopover.show({
    goodsId: goodsId.value,
    mallCode: detail.value.mall_code,
    config: {
      addSource: 'DetailPage',
      showBestDealLabel: true,
      showFollowBeltByOrigin: true,
      showEstimatedPrice: true,
      showNoSatisfied: true,
    },
    callback: {
      handleOpenQuickViewDone() {
        addToBagLoading.value = false
        handleAddCartPopupEvent()
      }
    }
  })
}
// 收藏
const hasSave = ref(false)
const isShowAddGroup = ref(false) // 是否显示加组提示

// 查询收藏状态
const checkLikeStatus = () => {
  schttp({
    url: '/api/user/wishlist/check/good',
    params: {
      goods_id: detail.value.goods_id,
    },
  }).then((res) => {
    if (
      res &&
      res.data &&
      Number(res.data.code) === 0 &&
      res.data.info
    ) {
      hasSave.value = Number(res.data.info.result) === 1
    }
  })
}
// 点击收藏逻辑
const loading = ref(false)
const handleClickAddWish = async() => {
  let type = hasSave.value ? 'del' : 'add'
  if(!isLogin()){
    return showLoginModal({ likeType: type, originRef: this, callback: () => {
      if(typeof window !== 'undefined') {
        // 登录成功之后，去掉快速注册
        window.gbRegisterModule && window.gbRegisterModule.rawHide()
        if (window.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window.gbCommonInfo?.WEB_CLIENT === 'shein') {
          handleClickAddWish()
        }
      }
    } })
  }
  let params = {}
  if (loading.value) return
  loading.value = true

  if (type === 'add') {
    const { goods_id, mall_code } = detail.value

    params = { 
      goods_id,
      mall_code,
    }

  } else if (type === 'del') {
    params = {
      goods_id: detail.value.goods_id,
    }
  }
  schttp({
    url: `/api/user/wishlist/${type}`,
    method: type === 'del' ? 'GET' : 'POST',
    ...(type === 'del' ? { params } : { data: params }),
  }).then(async (res) => {
    loading.value = false
    if (
      res &&
      res.data &&
      Number(res.data.code) === 0 &&
      res.data.info &&
      Number(res.data.info.result) === 1
    ) {
      if (type === 'add') {
        hasSave.value = true
        await checkNeedShowAddGroup()
      } else if (type === 'del') {
        hasSave.value = false
      }
      handleColectEvent({ is_cancel: type === 'add' ? 1 : 0, result: 1 })
    } else if (res?.data?.code == 400427) {
      // 超出收藏限制
      SMessage({
        message: template(
          res.data.info.limit,
          language.value.SHEIN_KEY_PC_19347
        ),
        type: 'warning',
        offset: 164,
        duration: 3000,
      })
      handleColectEvent({ is_cancel: type === 'add' ? 1 : 0, result: 0 })
    } else if (res?.data?.code == -1 || res?.data?.code == 100103 || res?.code == -1) {
      // 未登录
      handleColectEvent({ is_cancel: type === 'add' ? 1 : 0, result: 0 })
      return showLoginModal({ likeType: type, originRef: this, callback: () => {
        if(typeof window !== 'undefined') {
          // 登录成功之后，去掉快速注册
          window.gbRegisterModule && window.gbRegisterModule.rawHide()
          if (window.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window.gbCommonInfo?.WEB_CLIENT === 'shein') {
            handleClickAddWish()
          }
        }
      } })
      // showLoginModal({
      //   likeType: type
      // })
    }
  })
}

// 检查是否需要显示加组按钮
const checkNeedShowAddGroup = async () => {
  isShowAddGroup.value = false
  // eslint-disable-next-line @shein-aidc/abt/abt
  const { AddShowGroup } = await abtservice.getUserAbtResult({
    posKeys: 'AddShowGroup',
  })
  await schttp({
    url: '/api/user/wishlist/group/optional/goods/get',
  }).then(({ data }) => {
    const list = (data.info || {}).groupList || []
    const show = AddShowGroup.param === 'ShowGroup' || list.length
    isShowAddGroup.value = show
    sessionStorage.setItem('groupListData', JSON.stringify(list))
    daEventCenter.triggerNotice({
      daId: '1-8-6-47',
      extraData: { board_count: list.length ? 1 : 0 },
    })
    if(isShowAddGroup.value){
      setTimeout(() => {
        isShowAddGroup.value = false
      }, 5000)
    }
  }).catch(()=>{

  })
}

const addGroup = () => {
  daEventCenter.triggerNotice({
    daId: '1-8-6-48',
  })

  isShowAddGroup.value = false

  groupDialog().then(dialog => dialog.open(
    {
      type: 4,
      goodsIds: [detail.value.goods_id],
      useFrom: '商品详情页',
    },
    () => {}
  ))
}

const quickViewPopoverInstancePromise = ref(null)

// 收藏埋点
const handleColectEvent = (data) => {
  const postData = {
    abtest: '1',
    activity_from: 'main',
    goods_id: detail.value.goods_id,
    goods_list: goods_list.value,
    quickship_tp: '',
    mall_code: detail.value.mall_code,
    location: 'page',
    ...data,
  }
  daEventCenter.triggerNotice({
    daId: '1-69-1-7',
    extraData: postData
  })
}
// 加车埋点
const handleAddCartEvent = (data) => {
  const postData = {
    abtest: '',
    activity_from: 'main',
    goods_id: detail.value.goods_id,
    goods_list: goods_list.value,
    quickship_tp: '',
    mall_code: detail.value.mall_code,
    location: '',
    sku_code: detail.value.sku_code,
    sku_id: detail.value.sku_code,
    traceid: '',
    ...data,
  }
  daEventCenter.triggerNotice({
    daId: '1-69-1-5',
    extraData: postData
  })
}
// 加车埋点(唤起弹窗)
const handleAddCartPopupEvent = (data = {}) => {
  const postData = {
    abtest: '1',
    activity_from: 'main',
    goods_list: goods_list.value,
    style: 'detail',
    tab_list: '-',
    ...data,
  }
  daEventCenter.triggerNotice({
    daId: '1-69-1-6',
    extraData: postData
  })
}
onMounted(() => {
  checkLikeStatus()
  quickViewPopoverInstancePromise.value = import('public/src/pages/common/quickView').then(
    (comp) => comp.default
  )
})
</script>

<style lang="less">
.reviews-product {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  min-height: 100px;
  box-sizing: border-box;
  padding: 10px 0 30px 0;
  align-items: center;
  flex-shrink: 0;
  border: 0px solid #E5E5E5;
  background: #FFF;
  box-shadow: 0px -2px 4px 0px #F5F5F5;
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    max-width: 1102px;
    margin: 0 auto;
  }
  &__left {
    display: flex;
    align-items: center;
  }
  &__img {
    width: 60px;
    height: 60px;
    margin-right: 16px;
    border-radius: 6px;
    overflow: hidden;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__price {
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
  }
  &__intro {
    .reviews-product__title {
      color: #222;
      font-family: Arial;
      font-size: 14px;
      line-height: normal;
      margin-bottom: 10px;
    }
  }
  &__container-addBag {
    margin-right: 15px;
    // min-width: 183px;
    width: 350px;
    height: 54px;
    padding: 14px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #222222;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    color: #FFF;
    cursor: pointer;
    transition: background 0.2s;
  }
  &__container-addBag:hover {
    background: rgba(34, 34, 34, 0.8);
  }
  &__container-addBag.disabled {
    background: rgba(34,34,34,.6);
    cursor: not-allowed;
  }

  &__right {
    display: flex;
    align-items: center;
  }
  &__like {
    width: 54px;
    height: 54px;
    border: 1px solid #E5E5E5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &__like-icon:hover {
    scale: 1.1;
  }
}
  // 加组提示
  .product-intro__add-group {
    white-space: nowrap;
    font-size: 13px;
    width: auto;
    height: 28px;
    line-height: 28px;
    cursor: pointer;
    .sui_icon_addtoboard_28px_1 {
      font-size: 28px;
      margin-right: 8px;
      color: #222;
    }

    span {
      font-weight: bold;
      vertical-align: top;
    }
  }
</style>
