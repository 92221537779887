<template>
  <div 
    class="common-reviews__tag"
    :class="{ 'common-reviews__overflow': !isOpen }"
    :style="{ opacity: caleDone ? '1' : '0' }"
  >
    <div class="common-reviews__tag-list">
      <template
        v-for="(item) in tagList"
        :key="item.tagId"
      >
        <span
          
          ref="commonTagsItem"
          class="common-reviews__tag-item"
          :class="{ active: activeId == item.tagId, 'is-ar': cssRight && activeId == item.tagId }"
          :data-id="item.tagId"
          @click="handleTagClick(item.tagId)"
        >{{ item.tagName }} ({{ item.tagCmtNumShow }})</span>
      </template>
    </div>
    <div 
      v-show="isShowMore" 
      class="common-reviews__tag-more"
      @click="toggleMore"
    >
      <sui_icon_more_down_14px 
        :class="{rotate: isOpen}"
      />
    </div>
  </div>
</template>

<script>
import { webScrollExpose } from 'public/src/pages/common/analysis/ScrollExpose.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { 
  sui_icon_more_down_14px
} from '@shein-aidc/icon-vue3'
const { GB_cssRight } = gbCommonInfo
export default {
  name: 'ReviewsTag',
  components: {
    sui_icon_more_down_14px
  },
  props: {
    tagList: {
      type: Array,
      default: () => []
    },
    activeId: {
      type: String,
      default: ''
    },
    sourcePage: {
      // 页面来源
      type: String,
      default: 'detail'
    },
    position: {
      type: String,
      default: 'outer'
    },
    isNewDetail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cssRight: GB_cssRight,
      isOpen: true,
      isShowMore: false,
      caleDone: false
    }
  },
  watch: {
    tagList() {
      this.handleExposeAnalysis()
    }
  },
  mounted() {
    this.sourcePage === 'detail' && this.handleExposeAnalysis()
    this.calcTagsHeight()
  },
  beforeUnmount() {
    webScrollExpose.removeSubscriber(this.$refs['commonTagsItem'])
  },
  methods: {
    // 埋点
    handleExposeAnalysis() {
      this.$nextTick(() => {
        // 先移除
        webScrollExpose.removeSubscriber(this.$refs['commonTagsItem'])

        webScrollExpose.subscribe({
          dom: this.$refs['commonTagsItem'],
          mergeExpose: true,
          callback: ({ container }) => {
            daEventCenter.triggerNotice({
              daId: '1-8-5-36',
              target: container
            })
          }
        })
      })
    },
    handleTagClick(id) {
      if (id != this.activeId) {
        daEventCenter.triggerNotice({
          daId: '1-8-5-37',
          extraData: {
            id
          }
        })
      }
      this.$emit('reviewTagIdChange', id)
    },
    toggleMore() {
      this.isOpen = !this.isOpen
    },
    // 计算是否超过一行
    calcTagsHeight() {
      const tagWrapHeight = document.querySelector('.common-reviews__tag').clientHeight
      if(tagWrapHeight > 62) { // height37 + padding 25
        this.isShowMore = true
        this.isOpen = false
      }
      this.caleDone = true
    }
  },
  emits: ['reviewTagIdChange']
}
</script>

<style lang="less">
.common-reviews {
  &__tag {
    padding: 25px 0px 13px;
    position: relative;
    display: flex;
  }
  &__overflow {
    overflow: hidden;
    height: 37px;
    box-sizing: content-box;
  }
  &__tag-more {
    // position: absolute;
    // right: 0;
    // top: 32px;
    margin-top: 6px;
    cursor: pointer;
    .rotate {
      transform: rotate(180deg);
    }
  }
  &__tag-item {
    display: inline-block;
    height: 26px;
    line-height: 26px;
    padding: 0 12px;
    .margin-r(12px);
    margin-bottom: 12px;
    color: #666;
    border: 1px solid #e5e5e5;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      color: #666;
      border-color: #222 /*rw:#FF9999*/;
      text-decoration: none;
    }

    &.active {
      position: relative;
      color: #222;
      border-color: #222;
      /*rw:begin*/
      color: #ff9999;
      border-color: #ff9999;
      &:before {
        content: "";
        position: absolute;
        height: 18px;
        width: 18px;
        top: 0;
        .right(0);
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(/she_dist/images/filter-selected-457931bc64.png);
        /*rw:begin*/
        display: none;
      }
    }
    &.is-ar {
      &::before {
        transform: rotate(-270deg);
      }
    }
  }
}
</style>
