<template>
  <div
    v-show="tagShow"
    v-expose="{ id: '1-8-5-54' }"
    class="local-tag"
  >
    <Icon 
      name="sui_icon_location_16px_1" 
      size="16px" 
    />
    <span class="local-tag-text">{{ language.SHEIN_KEY_PC_23993 }}</span>
  </div>
</template>

<script>
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { Icon } from '@shein-aidc/icon-vue3'

export default {
  name: 'LocalTag',
  components: {
    Icon
  },
  directives: { expose },
  inject: ['COMMENT_INSTANCE'],
  props: {
    language: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    tagShow() {
      const { comment } = this
      return comment.local_flag === 1
    }
  }
}
</script>

<style lang="less" scoped>
.local-tag {
  font-size: 12px;
  color: @sui_color_gray_light1;
  display: flex;
  align-items: center;
  border-left: 1px solid @sui_color_gray_light2;
  padding-left: 6px;
  margin-left: 6px;

  .local-tag-text {
    margin-left: 2px;
  }
}
</style>
