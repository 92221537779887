<template>
  <div class="rate-fit">
    <span
      v-if="showFit"
      class="rate-fit__item"
      tabindex="0"
      role="text"
      :aria-label="`${language.SHEIN_KEY_PC_14650}`"
    >
      <span
        v-if="
          comment.member_cat_size &&
            fitMap[comment.member_cat_size.member_overall_fit]
        "
      >
        <strong>{{ language.SHEIN_KEY_PC_14647 }}</strong>
        {{ fitMap[comment.member_cat_size.member_overall_fit] }}
      </span>
      <span
        v-else-if="fitMap[comment.member_size.member_overall_fit]"
        class="rate-fit__item"
      >
        <strong>{{ language.SHEIN_KEY_PC_14647 }}</strong>
        {{ fitMap[comment.member_size.member_overall_fit] }}
      </span>
    </span>
    <template v-if="computedAttrList(comment).length">
      <span
        v-for="(attr, i) in computedAttrList(comment)"
        :key="`attr__${i}`"
        tabindex="0"
        :aria-label="attr.value"
        class="rate-fit__item"
      >
        <strong>{{ attr.key }}:</strong>
        {{ attr.value }}
      </span>
    </template>
    <template v-else>
      <span
        v-if="comment.color"
        class="rate-fit__item"
        tabindex="0"
        role="text"
        :aria-label="comment.color"
      >
        <strong>{{ language.SHEIN_KEY_PC_14639 }}:</strong>
        {{ comment.color }}
      </span>
      <span
        v-if="comment.size"
        class="rate-fit__item"
        tabindex="0"
        role="text"
        :aria-label="comment.size"
      >
        <strong>{{ language.SHEIN_KEY_PC_14670 }}:</strong>
        {{ comment.size }}
      </span>
    </template>
    <template v-if="showGoodsId">
      <span
        tabindex="0"
        class="goods-id"
        :class="{ 'check-skc-hook': comment.is_check_skc }"
        role="text"
        :aria-label="`${language.SHEIN_KEY_PC_24086}: ${comment.goods_id}`"
        @click="clickGoodsId(comment, index)"
      >
        <strong>{{ language.SHEIN_KEY_PC_24086 }}</strong>
        <Icon
          name="sui_icon_more_right_14px_1"
          size="14px"
          :custom-style="{ verticalAlign: 'top'}"
          :is-rotate="GB_cssRight" 
        />
      </span>
    </template>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'
const { GB_cssRight } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : { }

export default {
  name: 'CommentFit',
  components: {
    Icon
  },
  inject: ['COMMENT_INSTANCE'],
  data() {
    return {
      GB_cssRight
    }
  },
  computed: {
    language() {
      return this.COMMENT_INSTANCE.language
    },
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    fitMap() {
      return this.COMMENT_INSTANCE.fitMap
    },
    hasFit() {
      return this.COMMENT_INSTANCE.hasFit
    },
    showGoodsId() {
      return this.COMMENT_INSTANCE.showGoodsId
    },
    index() {
      return this.COMMENT_INSTANCE.index
    },
    showFit() {
      const { hasFit, comment, fitMap } = this
      return (
        hasFit &&
        ((comment.member_cat_size &&
          fitMap[comment.member_cat_size.member_overall_fit]) ||
          fitMap[comment.member_size.member_overall_fit])
      )
    }
  },
  methods: {
    clickGoodsId(comment, index) {
      const { COMMENT_INSTANCE } = this
      COMMENT_INSTANCE.EVENT_CENTER('goodsIdClick', { comment, index })
    },
    // 试用 & 评论做兼容
    computedAttrList(item) {
      const list = []
      if(item.sku_info_list?.length ) {
        item.sku_info_list.forEach((attr) => {
          list.push({
            key: attr.attribute_name_multi,
            value: attr.attribute_value_multi,
          })
        })
      } else if(item.sku_sale_attr?.length) {
        item.sku_sale_attr.forEach((attr) => {
          list.push({
            key: attr.attr_name,
            value: attr.attr_value_name,
          })
        })
      }
      return list
    },
  }
}
</script>
