<template>
  <div
    class="common-rate"
    role="text"
  >
    <span class="common-rate__star">

      <Icon
        v-for="n in rankData.full"
        :key="n"
        :index="n"
        class="common-rate__item common-rate__icon"
        :name="startIconName('full')"
        :size="(fontSize ? fontSize : size == 'big' ? 24 : 16) + 'px'"
        :custom-style="{ verticalAlign: 'none'}"
      />

      <Icon
        v-if="!!rankData.one"
        class="common-rate__item common-rate__icon"
        :name="startIconName('one')"
        :size="(fontSize ? fontSize : size == 'big' ? 24 : 16) + 'px'"
        :index="rankData.full + 1"
        :custom-style="{ verticalAlign: 'none'}"
      />

      <Icon
        v-if="!!rankData.two"
        class="common-rate__item common-rate__icon"
        :name="startIconName('two')"
        :size="(fontSize ? fontSize : size == 'big' ? 24 : 16) + 'px'"
        :index="rankData.full + 2"
        :custom-style="{ verticalAlign: 'none'}"
      />

      <Icon
        v-if="!!rankData.three"
        class="common-rate__item common-rate__icon"
        :name="startIconName('three')"
        :size="(fontSize ? fontSize : size == 'big' ? 24 : 16) + 'px'"
        :index="rankData.full + 3"
        :custom-style="{ verticalAlign: 'none'}"
      />

      <Icon
        v-for="n in rankData.empty"
        :key="`empty-${n}`"
        class="common-rate__item common-rate__icon"
        :index="
          rankData.full + rankData.three + rankData.two + rankData.one + n
        "
        :name="startIconName('empty')"
        :size="(fontSize ? fontSize : size == 'big' ? 24 : 16) + 'px'"
        :custom-style="{ verticalAlign: 'none'}"
      />
    </span>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'
export default {
  name: 'CommonRate',
  components: { Icon },
  props: {
    rankNum: {
      type: [String, Number],
      default: 5,
    },
    size: {
      type: String,
      default: 'big', // 'big' | 'small'
    },
    fontSize: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    rankData() {
      const { rankNum } = this
      const realNum = Math.min(5, rankNum)
      const fullCounts = Math.floor(realNum)
      const full = []
      for (let i = 0; i < fullCounts; i++) {
        full.push(i)
      }
      let one = 0,
          two = 0,
          three = 0
      const decimal = Number(parseFloat(realNum).toFixed(2).split('.')[1]) || 0

      if (decimal > 0) {
        if (decimal <= 25) {
          one = 1
        } else if (decimal <= 50) {
          two = 1
        } else if (decimal <= 99) {
          three = 1
        }
      }

      const emptyCounts = 5 - (fullCounts + one + two + three)
      const empty = []
      for (let i = 0; i < emptyCounts; i++) {
        empty.push(i)
      }

      return {
        full,
        one,
        two,
        three,
        empty,
      }
    }
  },
  methods: {
    startIconName(type) {
      if (type == 'full') {
        return this.size == 'big' ? 'sui_icon_star_5_24px' : 'sui_icon_star_5_16px_1'
      }
      if (type == 'one') {
        return this.size == 'big' ? 'sui_icon_star_2_24px' : 'sui_icon_star_2_16px_1'
      }
      if (type == 'two') {
        return this.size == 'big' ? 'sui_icon_star_3_24px' : 'sui_icon_star_3_16px_1'
      }
      if (type == 'three') {
        return this.size == 'big' ? 'sui_icon_star_4_24px' : 'sui_icon_star_4_16px_1'
      }
      if (type == 'empty') {
        return this.size == 'big' ? 'sui_icon_star_1_24px' : 'sui_icon_star_1_16px_1'
      }
    }
  }
}
</script>

<style lang="less">
.common-rate {
  &__star {
    font-size: 0;
  }
  &__item {
    display: inline-block;
    color: @sui_color_honor !important;  /* stylelint-disable-line declaration-no-important */
    & + .common-rate__item {
      .margin-l(3px);
    }
  }
  &__icon {
    transform: rotateY(0deg) /* rtl:rotateY(180deg) */;
  }
}
</style>
