export default {
  position: {
    type: String,
    default: 'outer'
  },
  comment: {
    type: Object,
    default() {
      return {}
    }
  },
  language: {
    type: Object,
    default() {
      return {}
    }
  },
  showMemberSize: {
    type: Boolean,
    default: true
  },
  sizeConfig: {
    type: Array,
    default() {
      return []
    }
  },
  fitMap: {
    type: Object,
    default() {
      return {}
    }
  },
  hasFit: {
    type: Number,
    default: 0
  },
  showLike: {
    type: Boolean,
    default: true
  },
  showGoodsId: {
    type: Boolean,
    default: false
  },
  analysis: {
    type: Object,
    default() {
      return {}
    }
  },
  skc: {
    type: String,
    default: ''
  },
  showPointProgram: {
    type: Boolean,
    default: false
  },
  // multiDimensionRate: {
  //   type: Boolean,
  //   default: false,
  // },
  markSingleLocalComment: {
    type: Boolean,
    default: false,
  },
  // 相似商品
  showSameFlag: {
    type: Boolean,
    default: true
  },
  // 商详页评论区图片水印配置
  // * 231114版本，仅在商详页注入，店铺评论页暂无
  watermarkConfig: {
    type: Object,
    default: {}
  },
  screenAbtConfig: {
    type: Object,
    default() {
      return {}
    }
  },
}
