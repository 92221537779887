<template>
  <span
    class="c-switch"
    :class="{ 'c-switch-checked': value }"
    @click="handleClick()"
  >
    <span class="c-switch-inner"></span>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    change: {
      type: Function,
      default: function () {},
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick: function () {
      if (this.disable) return
      this.$emit('update:value', !this.value)
      this.$emit('change', !this.value)
    },
  },
  emits: ['update:value', 'change'],
}
</script>
