<template>
  <div
    class="product-card__price-suggested"
    :class="{ 'flip-text': flipText }"
  >
    <template v-if="flipText">
      {{ labelText || '' }}:
      <del v-if="priceDeleteStyle">{{ value && value.amountWithSymbol }}</del>
      <span v-else>{{ value && value.amountWithSymbol }}</span>
    </template>
    <template v-else>
      <del v-if="priceDeleteStyle">{{ value && value.amountWithSymbol }}</del>
      <span v-else>{{ value && value.amountWithSymbol }}</span>
      {{ labelText || '' }}
    </template>
  </div>
</template>

<script>
/**
 * @file 合规价/划线价
 */

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SuggestedSalePrice',
  props: {
    value: {
      type: [Object, null],
      required: true,
    },
    labelText: {
      type: String,
      default: '',
    },
    flipText: {
      type: Boolean,
      default: false,
    },
    // 价格是否使用删除线样式
    priceDeleteStyle: {
      type: Boolean,
      default: false,
    }
  },
  setup() {},
})

</script>

<style lang="less" scoped>
.product-card__price-suggested {
  font-size: 12px;
  font-weight: 400;
  color: #959595;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
