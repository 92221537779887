<template>
  <ul
    v-show="selectTags.length"
    v-expose="getAnalysisData()"
    class="multi-rate-box"
  >
    <li
      v-for="(tag, index) in selectTags"
      :key="index"
    >
      <span class="tag-name">{{ tag.name }}:</span>
      <span>{{ tag.option_name }}</span>
    </li>
  </ul>
</template>

<script>
import { expose } from 'public/src/pages/common/analysis/directive.js'

export default {
  name: 'MultiRate',
  directives: { expose },
  inject: ['COMMENT_INSTANCE', 'REPORT_DATA'],
  computed: {
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    catId() {
      const { REPORT_DATA } = this
      return REPORT_DATA?.categoryId || ''
    },
    rankIndex() {
      const { comment } = this
      return comment?.rankIndex || 0
    },
    // 多维评分标签
    // * 长度为0表示无标签
    selectTags() {
      const { comment = {} } = this
      const { select_tag } = comment
      if (Array.isArray(select_tag) && select_tag.length) {
        const tags = select_tag.filter(tag => !!tag)
        if (tags.length) {
          return tags
        }
      }
      return []
    }
  },
  methods: {
    getAnalysisData() {
      const { selectTags, catId, rankIndex } = this
      if (!selectTags.length) {
        return {}
      }
      const tagIds = selectTags.map(tag => tag.id).join(',')
      return {
        id: '1-8-5-52',
        data: {
          multi_lable: tagIds,
          cat_id: catId,
          review_num: rankIndex
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.multi-rate-box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  color: #999;

  li {
    font-size: 13px;
    display: inline-block;
    margin-right: 16px;
  }

  .tag-name {
    font-weight: 700;
  }
}
</style>
