<template>
  <div tabindex="2">
    <div
      v-if="!hasCommentContent"
      class="rate-translate"
    >
      <span
        v-if="translateloading.includes(Number(comment.comment_id))"
        class="rate-translate-to"
      >{{ language.SHEIN_KEY_PC_17285 }}</span>
      <div
        v-else-if="isShowSelect"
        class="rate-translate-select"
      >
        <span class="rate-translate-to">{{ language.SHEIN_KEY_PC_17280 }}</span>
        <ul>
          <template
            v-for="(item, index) in translateList"
            :key="index"
          >
            <li>
              <button
                v-if="item.translate_language !== translateModel.lang"
                class="translate-button j-rate-translate-button"
                tabindex="0"
                role="botton"
                :aria-label="item.translate_language"
                @click="changeLanguage(item.translate_language)"
              >
                {{ item.display_language }}
              </button>
            </li>
          </template>
        </ul>
      </div>
      <button
        v-if="
          !translateloading.includes(Number(comment.comment_id)) &&
            !isShowSelect &&
            !isBatchTranslate
        "
        aria-label="translate"
        class="j-scroll-expose-translate j-translate-select"
        :data-review-id="comment.comment_id"
        @click="commentTranslate(comment)"
      >
        {{ language.SHEIN_KEY_PC_17244 }}
      </button>
      <button
        v-show="isBatchTranslate && !comment.reportId"
        aria-label="translate"
        class="j-scroll-expose-translate j-translate-select"
        :data-review-id="comment.comment_id"
        @click="showOriginComment"
      >
        {{
          showOrigin ? language.SHEIN_KEY_PC_17244 : language.SHEIN_KEY_PC_19657
        }}
      </button>
      <a
        v-if="isBatchTranslate && !showOrigin && !comment.reportId"
        href="http://translate.google.com"
        target="_blank"
      >
        <img
          class="rate-translate-by5"
          :src="`${PUBLIC_CDN || ''}/she_dist/images/greyscale-short-d098887b55.png`"
        />
      </a>
    </div>
    <div
      v-if="hasCommentContent"
      class="rate-translate-con"
    >
      <p
        ref="translateTxtRef"
        class="rate-translate-txt"
        role="text"
        tabindex="0"
      >
        {{ htmlDecode({ text: currentTransComment.translateCon || '' }) }}
      </p>
      <ul
        v-if="commentTags.length"
        class="comment-tag-box"
      >
        <li
          v-for="(tag, tagIndex) in commentTags"
          :key="tagIndex"
        >
          <span class="tag-name">{{ tag.name }}</span>: <span class="tag-content">{{ tag.content }}</span>
        </li>
      </ul>
      <i
        class="svgicon svgicon-close"
        @click="close()"
      ></i>
      <div>
        <div
          class="rate-translate-select"
          :class="{ romwe: isRomwe }"
        >
          <button
            class="j-translate-select toggle"
            tabindex="0"
            role="button"
            :data-out-store-review="comment.isOutStoreReview ? '1' : ''"
            :da-event-click="
              translateList.length > 1 &&
                !comment.reportId
                ? '1-8-5-21'
                : ''
            "
            @click="changeTranslateLanguage(comment)"
          >
            {{ getLang() }}
            <i
              v-if="translateList.length > 1"
              class="svgicon svg-arrow-down j-translate-select"
              :class="isShowSelect ? 'arrow-up' : ''"
            ></i>
          </button>
          <ul
            v-show="isShowSelect"
            v-if="translateList.length > 1"
          >
            <template
              v-for="(lg, index) in translateList"
              :key="index"
            >
              <li>
                <button
                  v-if="lg.translate_language !== translateModel.lang"
                  tabindex="0"
                  class="translate-button j-rate-translate-button"
                  :aria-label="lg.translate_language"
                  @click="changeLanguage(lg.translate_language)"
                >
                  {{ lg.display_language }}
                </button>
              </li>
            </template>
          </ul>
        </div>
        <a
          href="http://translate.google.com"
          target="_blank"
        >
          <img
            class="rate-translate-by"
            :src="`${PUBLIC_CDN || ''}/she_dist/images/greyscale-short-d098887b55.png`"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import 'public/src/icon/close.svg'
import 'public/src/icon/arrow-down.svg'
import { SMessage } from '@shein-aidc/sui-message'
import { daEventCenter } from 'public/src/services/eventCenter'
import { htmlDecode } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
const { PUBLIC_CDN, WEB_CLIENT } = gbCommonInfo

export default {
  props: {
    commentTranslateCache: {
      type: Object,
      default() {
        return {}
      },
    },

    currTranslateLang: {
      type: String,
      default: '',
    },
    translateloading: {
      type: Array,
      default() {
        return []
      },
    },
    translateModel: {
      type: Object,
      default() {
        return {}
      },
    },
    comment: {
      type: Object,
      default() {
        return {}
      },
    },
    spu: {
      type: String,
      default: '',
    },
    language: {
      type: Object,
      default() {
        return {}
      },
    },
    from: {
      type: String,
      default: '',
    },
    tempComment: {
      type: Object,
      default() {
        return {}
      },
    },
    isBatchTranslate: Boolean,
    isAutoTranslate: Boolean,
  },
  data() {
    return {
      PUBLIC_CDN,
      languageMap: {
        en: 'English',
        ar: 'العربي',
        de: 'Deutsch',
        fr: 'Français',
        es: 'Español',
        it: 'Italiano',
        ru: 'Pусский',
        th: 'ภาษาไทย',
        nl: 'Nederlands',
        vi: 'Tiếng Việt',
        he: 'עברית',
        'zh-tw': '繁體中文',
        'pt-br': 'Português（Brasil）',
        sv: 'Svenska',
        pl: 'Polski',
        id: 'Bahasa Indonesia',
        tr: 'Türk',
        'pt-pt': 'Português（Portugal）',
        ja: '日本語',
        ko: '한국어',
        'cs-cz': 'Čeština',
        'el-gr': 'Ελληνικά',
      },
      selectModal: false,
      showOrigin: false,
      WEB_CLIENT,
    }
  },
  computed: {
    translateList() {
      return this.comment?.translate_language || []
    },
    isRomwe() {
      return this.WEB_CLIENT === 'romwe'
    },
    // 当前是否存在评论内容，包括评论正文和标签
    hasCommentContent() {
      const { currentTransComment } = this
      if (!currentTransComment) {
        return false
      }

      const { translateCon, translateConTags } = currentTransComment
      if (translateCon) {
        return true
      }
      if (Array.isArray(translateConTags) && translateConTags.length) {
        return true
      }

      return false
    },
    isShowSelect() {
      const status = this.comment.comment_id === this.translateModel.comment_id
      return status
    },
    // 当前翻译评论内容
    currentTransComment() {
      const { commentTranslateCache, comment } = this
      if (!commentTranslateCache || !comment) {
        return null
      }
      return commentTranslateCache[comment.comment_id] || null
    },
    // 评论引导标签
    commentTags() {
      const { currentTransComment } = this
      if (!currentTransComment) {
        return []
      }
      const { translateConTags } = currentTransComment
      if (Array.isArray(translateConTags)) {
        return translateConTags.filter((tag) => tag && tag.name && tag.content)
      }
      return []
    },
  },
  watch: {
    isAutoTranslate() {
      this.comment.showOrigin = !this.isAutoTranslate
      this.showOrigin = !this.isAutoTranslate
    },
    tempComment() {
      if (this.comment.comment_id === this.tempComment.comment_id) {
        this.showOrigin = this.tempComment.showOrigin
      }
    },
    comment() {
      this.showOrigin = this.comment.showOrigin
    },
    isShowSelect: {
      handler(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            document.querySelector('.j-rate-translate-button').focus()
          })
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.showOrigin = !(localStorage.getItem('isOpen')
      ? Boolean(localStorage.getItem('isOpen') === 'true')
      : true)
  },
  methods: {
    getLang() {
      return (
        this.languageMap[
          this.commentTranslateCache[this.comment.comment_id].translateLang
        ] || ''
      )
    },
    calcLang(browerLang) {
      let browserLanguageMap = {
        'en-us': 'en',
        'en-uk': 'en',
        'en-au': 'en',
        'en-ca': 'en',
        'en-in': 'en',
        'en-irland': 'en',
        'en-za': 'en',
        'en-sg': 'en',
        'en-sa': 'en',
        'fr-ca': 'fr',
        'es-la': 'es',
      }
      return browserLanguageMap[browerLang] || browerLang
    },
    close() {
      daEventCenter.triggerNotice({
        daId: `1-8-5-${this.comment.reportId ? '32' : '22'}`,
      })
      this.$emit('close', this.comment.comment_id)
      this.$emit('refresh-modal', {
        show: false,
        lang: '',
        comment_id: '',
        cb: null,
      })
    },
    getRequestParams({ comment_id, comment_lang, curr_lang, isOutStoreReview }) {
      const isRp = comment_id.toString().indexOf('rp') > -1 
      const targetLang = curr_lang
      const bffOptions = {
        url: '/product/comment/translate',
        method: 'POST',
        useBffApi: true,
        params: {
          goods_spu: this.spu,
          comment_id,
          comment_language: comment_lang,
          src_language: comment_lang,
          dest_language: targetLang,
        }
      }
      if (isRp) {
        bffOptions.url = '/user/trial/free_trial_translate'
        bffOptions.method = 'GET'
        bffOptions.params = {
          report_id: comment_id.replace('rp', ''),
          target_language: targetLang,
        }
      }
      if (isOutStoreReview) {
        bffOptions.url = '/product/store/comment/translate'
        bffOptions.params = {
          comment_id,
          target_language: targetLang,
        }
      }
      return bffOptions
    },
    // 获取评论翻译
    async fetchCommentTranslate({
      comment_id = '',
      comment_lang = '',
      curr_lang = '',
      isOutStoreReview = false,
    }) {
      if (!this.translateloading.includes(comment_id))
        this.translateloading.push(Number(comment_id))
      const requestParams = this.getRequestParams({
        comment_id,
        comment_lang,
        curr_lang: curr_lang || localStorage.getItem('translate_lang'),
        isOutStoreReview,
      })
      const res = await schttp(requestParams)
      const lodingIndex = this.translateloading.findIndex(
        (item) => item === Number(comment_id)
      )
      const translateloading = [...this.translateloading]
      translateloading.splice(lodingIndex, 1)
      this.$emit('refresh-loading', translateloading)

      if (Number(res.code) != 0 || !res.info) {
        SMessage({
          message: this.language.SHEIN_KEY_PC_17245,
          type: 'error',
          offset: 180,
        })
        return
      }
      const info = res.info
      if (info && isOutStoreReview) {
        info.dest_text = info.language_content || ''
      }
      this.$emit('refresh-translate-cache', comment_id, info, curr_lang)
      this.$nextTick(() => {
        this.$refs['translateTxtRef']?.focus()
      })
    },
    commentTranslate(comment) {
      const { comment_id, language_flag, isOutStoreReview } = comment || {}
      let currTranslateLang = this.currTranslateLang
      // const isReport = this.comment.reportId
      if (!currTranslateLang) {
        let translate_lang =
          localStorage.getItem('translate_lang') ||
          (navigator.language || navigator.browserLanguage).toLowerCase()
        this.$emit('refresh-translate-lang', this.calcLang(translate_lang))
        currTranslateLang = this.calcLang(translate_lang)
      }
      this.$emit('refresh-modal', { comment_id: '' })
      if (
        !this.translateList.map(item => item.translate_language).includes(currTranslateLang)
      ) {
        if (this.translateList.length > 1) {
          this.$emit('refresh-modal', {
            show: true,
            lang: '',
            comment_id,
            cb: () => {
              setTimeout(() => {
                this.fetchCommentTranslate({ comment_id, language_flag, isOutStoreReview })
                const isReport = this.comment.reportId
                const daId = isReport ? '1-8-5-31' : '1-8-5-25'
                const extraData = {
                  review_id: comment_id,
                  translate_language: this.currTranslateLang,
                }
                if (!isReport) {
                  extraData.OutReview = isOutStoreReview ? 1 : 0
                  extraData.type = 'single'
                }
                daEventCenter.triggerNotice({ daId, extraData })
              }, 0)
            },
          })
          // const daId = isReport ? '1-8-5-29' : '1-8-5-23'
          // const extraData = {
          //   review_id: comment_id,
          //   translate_language: this.currTranslateLang || '',
          // }
          // if (!isReport) {
          //   extraData.OutReview = isOutStoreReview ? 1 : 0
          //   extraData.type = 'single'
          // }
          // daEventCenter.triggerNotice({ daId, extraData })
          return
        } else {
          currTranslateLang = this.translateList[0]
        }
      }
      setTimeout(() => {
        this.fetchCommentTranslate({
          comment_id,
          language_flag,
          curr_lang: currTranslateLang,
          isOutStoreReview,
        })
        // const daId = isReport ? '1-8-5-29' : '1-8-5-23'
        // const extraData = {
        //   review_id: comment_id,
        //   translate_language: this.currTranslateLang || '',
        // }
        // if (!isReport) {
        //   extraData.OutReview = isOutStoreReview ? 1 : 0
        //   extraData.type = 'single'
        // }
        // daEventCenter.triggerNotice({ daId, extraData })
      }, 0)
    },
    changeTranslateLanguage(comment) {
      const { comment_id, language_flag, isOutStoreReview } = comment
      const { translateLang } = this.currentTransComment || {}
      if (this.comment.reportId) {
        daEventCenter.triggerNotice({
          daId: '1-8-5-30',
        })
      }
      this.$emit('refresh-modal', {
        show: true,
        lang: translateLang,
        comment_id: this.isShowSelect ? '' : comment_id,
        cb: () => {
          setTimeout(() => {
            this.fetchCommentTranslate({ comment_id, language_flag, isOutStoreReview })
            const isReport = this.comment.reportId
            const daId = isReport ? '1-8-5-31' : '1-8-5-25'
            const extraData = {
              review_id: comment_id,
              translate_language: this.currTranslateLang,
            }
            if (!isReport) {
              extraData.OutReview = isOutStoreReview ? 1 : 0
              extraData.type = 'single'
            }
            daEventCenter.triggerNotice({ daId, extraData })
          }, 0)
        },
      })
    },
    changeLanguage(lang) {
      this.$emit('refresh-translate-lang', lang)
      localStorage.setItem('translate_lang', lang)
      setTimeout(() => {
        if (this.translateModel.cb) this.translateModel.cb()
        this.$emit('refresh-modal', {
          show: false,
          lang: '',
          comment_id: '',
          cb: null,
        })
      }, 0)
    },
    showOriginComment() {
      this.comment.showOrigin = !this.comment.showOrigin
      this.showOrigin = this.comment.showOrigin
      daEventCenter.triggerNotice({
        daId: '1-8-5-40',
        extraData: {
          showOrigin: this.comment.showOrigin,
          review_id: this.comment.comment_id,
        },
      })
      this.$emit('updateTempComment', {
        showOrigin: this.comment.showOrigin,
        comment_id: this.comment.comment_id,
      })
    },
    htmlDecode,
  },
  emits: [
    'close',
    'refresh-modal',
    'refresh-loading',
    'refresh-translate-cache',
    'refresh-translate-lang',
    'updateTempComment',
  ],
}
</script>

<style lang="less">
.rate-translate {
  color: @color_blue_dark;
  margin-bottom: 15px;
  font-size: 12px;
  cursor: pointer;
  button {
    color: @sui_color_link;
    cursor: pointer;
    border: none;
    background: transparent;
  }
}
.rate-translate-con {
  margin-bottom: 15px;
  background: #f5f4f5;
  padding: 10px;
  position: relative;
  .padding-r(24px);
  .svgicon-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
    cursor: pointer;
    transform: scale(0.7);
  }
}
.rate-translate-txt {
  color: #222;
  font-size: 16px;
  margin-bottom: 10px;
  word-break: break-word;
}
.comment-tag-box {
  color: @sui_color_gray_dark2;
  font-size: 16px;
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 16px;
  .tag-content {
    color: #222;
  }
}
.rate-translate {
  span {
    color: @color_blue_dark;
  }
  .rate-translate-to {
    color: #999999;
  }
}
.rate-translate-select {
  position: relative;
  z-index: @zindex-hack;
  display: inline-block;
  button {
    color: @color_blue_dark;
    cursor: pointer;
    border: none;
    height: unset;
    background: transparent;
  }
  ul {
    position: absolute;
    top: 15px;
    .left(0);
    padding: 5px 0;
    min-width: 140px;
    background-color: #fff;
    font-size: 12px;
    border: 1px solid #f6f6f6;
    max-height: 192px;
    overflow-y: auto;
    z-index: @zindex-hack;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
    color: #222;
    /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
    -ms-overflow-style: none;
    /*火狐下隐藏滚动条*/
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    /*Chrome下隐藏滚动条，溢出可以透明滚动*/
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  li {
    padding: 0 40px 0 15px; /*rtl:ignore*/
    line-height: 34px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
    .translate-button {
      background: transparent;
      height: unset;
      border: none;
    }
  }
  .svgicon.arrow-up {
    transform: rotate(180deg);
  }

  &.romwe {
    button,
    .translate-button {
      color: #222;
    }
    .j-translate-select.toggle {
      color: @sui_color_link;
    }
  }
}
.rate-translate-by {
  height: 14px;
  .margin-l(10px);
}
.rate-translate-by5 {
  height: 14px;
  .margin-l(5px);
}
</style>
