export const OriginSizes = [
  ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'XXXXL', '0XL', '1XL', '2XL', '3XL', '4XL', '5XL', '6XL', '7XL'],
  ['24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46'],
  ['W24 L28', 'W25 L28', 'W26 L28', 'W28 L28', 'W30 L28', 'W25 L32', 'W26 L32', 'W27 L32', 'W28 L32', 'W29 L32', 'W30 L32', 'W31 L32', 'W32 L32', 'W26 L36', 'W27 L36', 'W28 L36', 'W30 L36', 'W32 L36', 'W34 L36'],
  ['US18', 'US20', 'US22', 'US24', 'US26', 'US28', 'US32', 'US34', 'US36', 'US38', 'US24/26', 'US28/30', 'US32/34', 'US36/38', ]
]

export const COMMENT_FUZZY_LIMIT = 1000

// 评论未登陆状态下最多显示数量
export const COMMENT_NO_LOGIN_SHOW_LIMIT = 150

export const LOCAL_COMMENT_ABT_FLAG_MAP = new Map()
LOCAL_COMMENT_ABT_FLAG_MAP.set('type=B', 1)
LOCAL_COMMENT_ABT_FLAG_MAP.set('type=C', 2)
LOCAL_COMMENT_ABT_FLAG_MAP.set('type=D', 3)
LOCAL_COMMENT_ABT_FLAG_MAP.set('type=E', 4)
