import { ref, computed, watch, nextTick, onMounted, onBeforeUnmount } from 'vue'


/**
 * 商卡popover hook
 * @param {{
 *  goodsInfo: Object, // 商品信息
 * 
 * }} options 
 */
export const useCardPopover = (options) => {
  const goodsInfo = options?.goodsInfo
  // 唯一id
  const id = ref('')
  // 是否激活pop弹窗
  const popoverActive = ref(false)

  // 是否展示icon
  const iconCaution = ref(null)
  const showIconSuggested = ref(0)

  const popoverClick = (e) => {
    const { target } = e
    if (!popoverActive.value) {
      const drawerDom = target.closest('.sui-drawer__container') 
      const dialogDom = target.closest('.sui-dialog__body') 
      if (drawerDom || dialogDom){
        popoverZindex.value = 999999
      } else {
        popoverZindex.value = 1
      }


      nextTick(() => {
        popoverActive.value = true
        window.deSuggestedSaleComponnet.popoverActiveId.value = id.value

        bingDialogScollEvent(e)
      })
    }
  }

  const closePopover = () => {
    if (window.deSuggestedSaleComponnet.popoverActiveId.value) {
      window.deSuggestedSaleComponnet.popoverActiveId.value = ''
    }
  }

  // 如果在弹窗中，绑定弹窗scroll事件
  let dialogBodyDom = null
  let drawerBodyDom = null
  let recommendListMoreDom = null
  let getthelooknewContent = null

  const bingDialogScollEvent = (e) => {
    // dialog弹窗
    const inDialog = e.target.closest('.sui-dialog__body')
    if (inDialog && !dialogBodyDom) {
      dialogBodyDom = inDialog
      dialogBodyDom.addEventListener('scroll', closePopover)
    }

    // drawer弹窗
    const inDrawerBodyDom = e.target.closest('.sui-drawer__body')
    if (inDrawerBodyDom && !drawerBodyDom) {
      drawerBodyDom = inDrawerBodyDom
      drawerBodyDom.addEventListener('scroll', closePopover)
    }

    // cccx横滑弹窗
    const inRecommendListMoreDom = iconCaution.value?.closest?.('.recommend-list-more')
    if (inRecommendListMoreDom && !recommendListMoreDom) {
      recommendListMoreDom = inRecommendListMoreDom
      recommendListMoreDom.addEventListener('scroll', closePopover)
    }

    // 商详新搭配
    const inGetthelooknewContentDom = iconCaution.value?.closest?.('.getthelooknew-content')
    if (inGetthelooknewContentDom && !getthelooknewContent) {
      getthelooknewContent = inGetthelooknewContentDom
      getthelooknewContent.addEventListener('scroll', closePopover)
    }
  }

  const popoverZindex = ref(1)

  const popoverStyle = computed(() => {
    return {
      zIndex: popoverZindex.value,
      transform: `translateZ(${popoverZindex.value}px)`,
      maxWidth: '240px'
    }
  })

  onMounted(() => {
    const { goods_id, goods_sn } = goodsInfo
    id.value = `${goods_id}_${goods_sn}`

    const handleFun = () => {
      // 判断icon是否够位置展示
      // 没有被压缩变形，宽度高度一致，证明可以展示icon
      const { width, height } = iconCaution.value?.getBoundingClientRect()
      if (width >= height) {
        showIconSuggested.value = 1
      } else {
        showIconSuggested.value = -1
      }

      // 点击滑动除popover外任意部分需要隐藏popover
      if (!window.deSuggestedSaleComponnet) {
        window.deSuggestedSaleComponnet = {
          popoverActiveId: ref(''),
          touchEvent: window.document.addEventListener('click', (e) => {
            const { target } = e

            const popoverActiveId = window.deSuggestedSaleComponnet.popoverActiveId.value
            if (popoverActiveId && !target.className.includes('sui-popover__content')) {
              const parentElement = target.parentElement.parentElement
              
              const priceWrapperElement = parentElement.querySelector('.bottom-wrapper__price-wrapper')
              const popoverElement = parentElement.querySelector('.icon-suggested-caution')
              const popoverId = popoverElement?.getAttribute('data-id')
              if (!priceWrapperElement && popoverId === popoverActiveId) return false

              window.deSuggestedSaleComponnet.popoverActiveId.value = ''
            }
          }),
          scrollEvent: window.document.addEventListener('scroll', closePopover)
        }
      }

      watch(
        window.deSuggestedSaleComponnet.popoverActiveId,
        (newVal) => {
          // 避免多次打开关闭
          nextTick(() => {
            if ((!newVal || newVal !== id.value) && popoverActive.value) {
              popoverActive.value = false
            }
          })
        }
      )
    }

    if (window.requestIdleCallback) {
      window.requestIdleCallback(handleFun)
    } else {
      setTimeout(handleFun, 50)
    }
  })

  onBeforeUnmount(() => {
    dialogBodyDom && dialogBodyDom.removeEventListener('scroll', closePopover)
    recommendListMoreDom && recommendListMoreDom.removeEventListener('scroll', closePopover)
    getthelooknewContent && getthelooknewContent.removeEventListener('scroll', closePopover)
    drawerBodyDom && drawerBodyDom.removeEventListener('scroll', closePopover)
  })

  return {
    id,
    popoverActive,
    iconCaution,
    showIconSuggested,
    popoverStyle,
    popoverClick
  }
}
